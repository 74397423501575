import {PromiseAction} from 'redux/store/middleware/promise';
import {PromiseStatus} from 'utils/status';

export function isLoading(action: PromiseAction<unknown> | {data: unknown}) {
  return 'state' in action && action.state === PromiseStatus.request;
}

export function isFail(action: PromiseAction<unknown> | {data: unknown}) {
  return 'state' in action && action.state === PromiseStatus.failure;
}
