import moment from 'moment-timezone';

import {LabelsByArgumentType} from 'toolkit/format/format';
import * as Types from 'types';

export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_HOURS_MINUTES_FORMAT = 'YYYY-MM-DDTHH:mmZ';
export const DATE_HOURS_MINUTES_READABLE_FORMAT = 'YYYY-MM-DD HH:mm';
export const DATE_HOURS_MINUTES_SECONDS_READABLE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const NAN_STRING = '—';
export const INFINITY_SYMBOL = '∞';
export const SHORT_DISPLAY_DATE_FORMAT = 'MMM D';
export const DISPLAY_DATE_FORMAT = 'MMM D, YYYY';
export const US_DATE_FORMAT = 'MM/DD/YYYY';
export const TIME_FORMAT = 'HH:mmZ';
export const TIME_FORMAT_12H = 'h:mm A zz';
export const TIME_FORMAT_VERBOSE_TIMEZONE = 'HH:mm zz';
export const WEEKDAY_DISPLAY_DATE_FORMAT = 'dddd, MMM D, YYYY';

export const VALID_SNAKE_CASE_NAME_REGEX = /^[a-z0-9_]+$/;

export const isValidDateString = (date: string) => {
  // date is assumed to be in DATE_FORMAT
  if (!moment(date).isValid()) {
    return false;
  }
  // moment("1").isValid() returns true, so we need to investigate further
  return date.length === DATE_FORMAT.length;
};

export const LABELS = {
  errorMetric: {
    [Types.ErrorMetric.MAPE]: 'MAPE',
    [Types.ErrorMetric.WAPE]: 'WAPE',
    [Types.ErrorMetric.ROLLING_WAPE]: 'Rolling WAPE',
    [Types.ErrorMetric.MEAN_ERROR]: 'Mean Error',
    [Types.ErrorMetric.TRACKING_SIGNAL]: 'Tracking Signal',
    [Types.ErrorMetric.MEAN_PERCENTAGE_ERROR]: 'Mean Percentage Error',
  },
  forecastType: {
    [Types.ForecastType.PARTNER]: 'Partner',
    [Types.ForecastType.VENDOR]: 'Internal',
    [Types.ForecastType.ALLOY]: 'Alloy.ai',
    [Types.ForecastType.ENSEMBLE]: 'Ensemble',
    [Types.ForecastType.PROPHET]: 'GAM (Generalized Additive Model)',
    [Types.ForecastType.THETA]: 'Optimized Theta Method',
    [Types.ForecastType.ETS]: 'ETS (Exponential Moving Average)',
    [Types.ForecastType.USAGE]: 'Usage',
    [Types.ForecastType.HISTORICAL_AVERAGE]: 'Historical Average',
    [Types.ForecastType.SEASONAL_HISTORICAL_AVERAGE]: 'Seasonal Historical Average',
    [Types.ForecastType.ANNUAL_GROWTH]: 'Annual Growth',
    [Types.ForecastType.AOP]: 'AOP (Annual Operating Plan)',
    [Types.ForecastType.CONSENSUS]: 'Consensus',
    [Types.ForecastType.FIELD]: 'Field',
    [Types.ForecastType.PLAN]: 'Plan',
    [Types.ForecastType.EXPERIMENTAL]: 'Experimental',
    [Types.ForecastType.EXPERIMENTAL_TWO]: 'Experimental Two',
  },
  granularity: {
    [Types.CalendarUnit.DAYS]: 'Day',
    [Types.CalendarUnit.WEEKS]: 'Week',
    [Types.CalendarUnit.MONTHS]: 'Month',
    [Types.CalendarUnit.QUARTERS]: 'Quarter',
    [Types.CalendarUnit.SEASONS]: 'Season',
    [Types.CalendarUnit.YEARS]: 'Year',
  },
  forecastComposition: {
    [Types.ForecastComposition.BASELINE]: 'Baseline',
    [Types.ForecastComposition.BASELINE_WITH_ADJUSTMENTS]: 'Baseline with Adjustments',
    [Types.ForecastComposition.TOTAL]: 'Total',
    [Types.ForecastComposition.EVENT_LIFT]: 'Event Lift',
  },
  inventoryMeasure: {
    [Types.InventoryMeasure.UNITS]: 'Units',
    [Types.InventoryMeasure.RETAIL]: 'Retail Price',
    [Types.InventoryMeasure.COST]: 'Sell-in Price',
  },
  inventoryTypes: {
    [Types.InventoryType.ON_HAND]: 'On Hand',
    [Types.InventoryType.IN_TRANSIT]: 'In Transit',
    [Types.InventoryType.ALLOCATED]: 'Allocated',
    [Types.InventoryType.AVAILABLE]: 'Available',
    [Types.InventoryType.ON_ORDER]: 'On Order',
    [Types.InventoryType.INTERNAL_ON_ORDER]: 'On Order (Internal)',
    [Types.InventoryType.EXPECTED_ON_HAND]: 'Expected',
  },
  inflowTypes: {
    [Types.InflowType.FORECASTED_ORDERS]: 'Forecasted Orders',
    [Types.InflowType.FORECASTED_RECEIPTS]: 'Forecasted Receipts',
    [Types.InflowType.FORECASTED_SHIPMENTS]: 'Forecasted Shipments',
    [Types.InflowType.SCHEDULED_SHIPMENTS]: 'Scheduled Shipments',
    [Types.InflowType.SCHEDULED_RECEIPTS]: 'Scheduled Receipts',
    [Types.InflowType.SCHEDULED_RECEIPTS_ON_ORDER]: 'Scheduled Receipts (On Order)',
    [Types.InflowType.SCHEDULED_RECEIPTS_IN_TRANSIT]: 'Scheduled Receipts (In Transit)',
    [Types.InflowType.REQUESTED_RECEIPTS]: 'Requested Receipts',
    [Types.InflowType.REQUESTED_RECEIPTS_ON_ORDER]: 'Requested Receipts (On Order)',
    [Types.InflowType.REQUESTED_RECEIPTS_IN_TRANSIT]: 'Requested Receipts (In Transit)',
    [Types.InflowType.PLANNED_RECEIPTS]: 'Planned Receipts',
    [Types.InflowType.PLANNED_SHIPMENTS]: 'Planned Shipments',
    [Types.InflowType.PROJECTED_RECEIPTS_IN_TRANSIT]: 'Projected Receipts (In Transit)',
    [Types.InflowType.LATE_SCHEDULED_RECEIPTS]: 'Late Scheduled Receipts',
    [Types.InflowType.LATE_REQUESTED_RECEIPTS]: 'Late Requested Receipts',
  },
  outflowTypes: {
    [Types.OutflowType.ACTUAL_TOTAL_SHIPMENTS]: 'Actual Total Shipments',
    [Types.OutflowType.UNIT_SALES]: 'Forecasted Unit Sales',
    [Types.OutflowType.FORECASTED_SHIPMENTS]: 'Forecasted Shipments',
    [Types.OutflowType.DOWNSTREAM_FORECASTED_SHIPMENTS]: 'Downstream Forecasted Shipments',
    [Types.OutflowType.SCHEDULED_SHIPMENTS]: 'Scheduled Shipments',
    [Types.OutflowType.REQUESTED_SHIPMENTS]: 'Requested Shipments',
    [Types.OutflowType.PLANNED_SHIPMENTS]: 'Planned Shipments',
    [Types.OutflowType.FORECASTED_SHIPMENTS_BLENDED]: 'Blended Shipments',
    [Types.OutflowType.INTERNAL_SCHEDULED_SHIPMENTS]: 'Internal Scheduled Shipments',
    [Types.OutflowType.LATE_SCHEDULED_SHIPMENTS]: 'Late Scheduled Shipments',
    [Types.OutflowType.LATE_REQUESTED_SHIPMENTS]: 'Late Requested Shipments',
    [Types.OutflowType.LATE_INTERNAL_SCHEDULED_SHIPMENTS]: 'Late Internal Scheduled Shipments',
    [Types.OutflowType.DOWNSTREAM_PLANNED_SHIPMENTS]: 'Downstream Planned Shipments',
  },
  stockAggregator: {
    [Types.StockAggregator.STARTING]: 'Starting',
    [Types.StockAggregator.ENDING]: 'Ending',
    [Types.StockAggregator.AVERAGE]: 'Average',
    [Types.StockAggregator.LAST_KNOWN]: 'Last Known',
  },
  timeUnit: {
    [Types.CalendarUnit.DAYS]: 'Days',
    [Types.CalendarUnit.WEEKS]: 'Weeks',
  },
  salesType: {
    [Types.SalesType.TOTAL]: 'Total',
    [Types.SalesType.REGULAR]: 'Regular',
    [Types.SalesType.CLEARANCE]: 'Clearance',
    [Types.SalesType.PROMO]: 'Promo',
  },
  returnsCountingMethod: {
    [Types.ReturnsCountingMethod.GROSS]: 'Gross',
    [Types.ReturnsCountingMethod.NET]: 'Net',
    [Types.ReturnsCountingMethod.ONLY]: 'Returns Only',
  },
  storeType: {
    [Types.StoreType.TRACKED]: 'Tracked',
    [Types.StoreType.ACTIVE]: 'Active',
    [Types.StoreType.SCANNING]: 'Scanning',
  },
  perspective: {
    [Types.GraphPerspective.AT_LOCATION]: 'At Location',
    [Types.GraphPerspective.UPSTREAM]: 'Upstream',
    [Types.GraphPerspective.DOWNSTREAM]: 'Downstream',
  },
  outOfStockCalculationMethod: {
    [Types.OutOfStockCalculationMethod.OUT_OF_STOCK]: 'Out of Stock',
    [Types.OutOfStockCalculationMethod.BELOW_PRESMIN]: 'Below presentation minimum',
  },
  dollarType: {
    // If you edit this, make sure to edit the equivalent display names in DollarType in Pewter
    [Types.DollarType.RETAIL_PRICE]: 'Retail Price',
    [Types.DollarType.SELL_IN_PRICE]: 'Sell-in Price',
    [Types.DollarType.MANUFACTURING_COST]: 'Manufacturing Cost',
  },
  weekFormat: {
    [Types.WeekFormat.CALENDAR_FORMAT]: 'Numbered weeks',
    [Types.WeekFormat.START_OF_WEEK]: 'Start of week',
    [Types.WeekFormat.END_OF_WEEK]: 'End of week',
  },
  planAdjustmentMode: {
    [Types.PlanAdjustmentMode.TOTAL]: 'Total',
    [Types.PlanAdjustmentMode.BASELINE]: 'Baseline',
  },
  dateFormat: {
    '': 'YYYY-MM-DD',
    yyyyMMdd: 'YYYYMMDD',
    "yyyy-MM-dd'T'HH:mm:ss": 'YYYY-MM-DDTHH:mm:ss',
  },
  exportFormat: {
    [Types.ExportFormat.CSV]: 'CSV',
    [Types.ExportFormat.EXCEL]: 'Excel',
  },
  statisticalAggregator: {
    mean: 'Average',
    minimum: 'Minimum',
    maximum: 'Maximum',
    standard_deviation: 'Standard deviation',
    variance: 'Variance',
    deviations_from_mean: 'Std Deviations from Mean',
    none: 'None',
  },
} satisfies LabelsByArgumentType;
