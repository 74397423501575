import {Middleware} from 'redux';

import {Dispatch} from 'redux/reducers';
import {ApiError} from 'types/error';
import {captureException} from 'utils/exceptions';
import {BinaryStatus, PromiseStatus} from 'utils/status';

export type PromiseAction<T> =
  | {
      error: ApiError;
      promise: Promise<T>;
      state: PromiseStatus.failure;
    }
  | {
      data: T;
      promise: Promise<T>;
      state: PromiseStatus.success;
    }
  | {
      promise: Promise<T>;
      state: PromiseStatus.request;
    };

const reduxPromiseMiddleware: Middleware = () => {
  return (dispatch: Dispatch) => (action: any) => {
    const {promise, ...act} = action;
    if (!promise) {
      return dispatch(action);
    }

    dispatch({...act, state: PromiseStatus.request});
    return promise
      .then((result: any) => {
        dispatch({...act, data: result, state: PromiseStatus.success});
        return BinaryStatus.succeeded;
      })
      .catch((error: ApiError) => {
        captureException(error);
        dispatch({...act, error, state: PromiseStatus.failure});
        return BinaryStatus.failed;
      });
  };
};
export default reduxPromiseMiddleware;
