import {LOCATION_CHANGE} from 'connected-react-router';

import {TagOption} from 'data/integrations/IntegrationsBrowser';
import {NavigationAction} from 'redux/actions/navigation';
import ActionType from 'redux/actions/types';
import {SortModel} from 'toolkit/ag-grid/types';
import {DateSortType} from 'toolkit/analysis/types';
import {ALL_SELECTED, EntityTableColumn, SelectableValues} from 'toolkit/entities/types';
import {BreadcrumbItem} from 'toolkit/views/types';
import {getPlanningTypeMatch, getViewPathMatch} from 'toolkit/views/utils';
import * as Types from 'types';
import {toHref} from 'utils/url';

interface SearchState {
  readonly filterText: string;
  readonly selectedValuesByProperty: {readonly [property: string]: SelectableValues};
}

interface IntegrationsSearchState {
  readonly filterText: string;
  readonly selectedCountries: readonly string[];
  readonly selectedTypes: readonly Types.DataIntegrationType[];
  readonly selectedTags: readonly TagOption[];
  readonly scrollPosition: number;
  readonly isAddingDataSource: boolean;
}

interface GlobalSearchState {
  readonly filterText: string;
  readonly selectedTags: readonly string[];
}

interface ManagementState {
  readonly searchState: SearchState;
  readonly dateSortType: DateSortType;
  readonly sortModel: SortModel;
}

export interface NavigationState {
  readonly dashboardManagementState: ManagementState;
  readonly eventManagementState: ManagementState;
  readonly destinationManagementState: ManagementState;
  readonly integrationsPageSearchState: IntegrationsSearchState;
  readonly globalSearchState: GlobalSearchState;
  readonly isGlobalCreateMenuOpen: boolean;
  readonly isUserMenuOpen: boolean;
  readonly redirectUrl: string | null;
  readonly planBreadcrumb: BreadcrumbItem | null;
  readonly returnTo: BreadcrumbItem | null;
  readonly referrer: string;
  readonly currentUrl: string;
  readonly isNavigationInProgress: boolean;
}
const initialState: NavigationState = {
  dashboardManagementState: {
    dateSortType: DateSortType.LastOpenedByMe,
    searchState: {
      filterText: '',
      selectedValuesByProperty: {type: ALL_SELECTED, status: ALL_SELECTED},
    },
    sortModel: [{colId: EntityTableColumn.LAST_VIEWED, sort: 'desc'}],
  },
  eventManagementState: {
    dateSortType: DateSortType.LastModified,
    searchState: {
      filterText: '',
      selectedValuesByProperty: {type: ALL_SELECTED, status: ALL_SELECTED},
    },
    sortModel: [{colId: EntityTableColumn.LAST_UPDATED, sort: 'desc'}],
  },
  destinationManagementState: {
    dateSortType: DateSortType.LastModified,
    searchState: {
      filterText: '',
      selectedValuesByProperty: {type: ALL_SELECTED, status: ALL_SELECTED},
    },
    sortModel: [{colId: EntityTableColumn.LAST_TRIED, sort: 'desc'}],
  },
  integrationsPageSearchState: {
    filterText: '',
    selectedCountries: [],
    selectedTypes: [],
    selectedTags: [],
    scrollPosition: 0,
    isAddingDataSource: false,
  },
  globalSearchState: {
    filterText: '',
    selectedTags: [],
  },
  isGlobalCreateMenuOpen: false,
  isUserMenuOpen: false,
  redirectUrl: null,
  planBreadcrumb: null,
  returnTo: null,
  referrer: window.document.referrer,
  currentUrl: toHref(window.document.location),
  isNavigationInProgress: false,
};

export default function navigation(
  state = initialState,
  action: NavigationAction
): NavigationState {
  if (action.type === ActionType.SetUserMenuOpen) {
    return {...state, isUserMenuOpen: action.isMenuOpen};
  } else if (action.type === ActionType.SetGlobalCreateMenuOpen) {
    return {...state, isGlobalCreateMenuOpen: action.isMenuOpen};
  } else if (action.type === ActionType.SetDashboardManagementFilterText) {
    return {
      ...state,
      dashboardManagementState: {
        ...state.dashboardManagementState,
        searchState: {
          ...state.dashboardManagementState.searchState,
          filterText: action.filterText,
        },
      },
    };
  } else if (action.type === ActionType.SetEventManagementFilterText) {
    return {
      ...state,
      eventManagementState: {
        ...state.eventManagementState,
        searchState: {
          ...state.eventManagementState.searchState,
          filterText: action.filterText,
        },
      },
    };
  } else if (action.type === ActionType.SetDestinationManagementFilterText) {
    return {
      ...state,
      destinationManagementState: {
        ...state.destinationManagementState,
        searchState: {
          ...state.destinationManagementState.searchState,
          filterText: action.filterText,
        },
      },
    };
  } else if (action.type === ActionType.SetEventManagementActiveFilter) {
    return {
      ...state,
      eventManagementState: {
        ...state.eventManagementState,
        searchState: {
          ...state.eventManagementState.searchState,
          selectedValuesByProperty: action.selectedValuesByProperty,
        },
      },
    };
  } else if (action.type === ActionType.SetDestinationManagementActiveFilter) {
    return {
      ...state,
      destinationManagementState: {
        ...state.destinationManagementState,
        searchState: {
          ...state.destinationManagementState.searchState,
          selectedValuesByProperty: action.selectedValuesByProperty,
        },
      },
    };
  } else if (action.type === ActionType.SetDashboardManagementTableDateSortType) {
    return {
      ...state,
      dashboardManagementState: {
        ...state.dashboardManagementState,
        dateSortType: action.dateSortType,
      },
    };
  } else if (action.type === ActionType.SetDashboardManagementTableSortModel) {
    return {
      ...state,
      dashboardManagementState: {...state.dashboardManagementState, sortModel: action.sortModel},
    };
  } else if (action.type === ActionType.SetGlobalSearchFilterText) {
    return {
      ...state,
      globalSearchState: {
        ...state.globalSearchState,
        filterText: action.filterText,
      },
    };
  } else if (action.type === ActionType.SetDashboardManagementActiveFilter) {
    return {
      ...state,
      dashboardManagementState: {
        ...state.dashboardManagementState,
        searchState: {
          ...state.dashboardManagementState.searchState,
          selectedValuesByProperty: action.selectedValuesByProperty,
        },
      },
    };
  } else if (action.type === ActionType.SetEventManagementTableSortModel) {
    return {
      ...state,
      eventManagementState: {...state.eventManagementState, sortModel: action.sortModel},
    };
  } else if (action.type === ActionType.SetDestinationManagementTableSortModel) {
    return {
      ...state,
      destinationManagementState: {
        ...state.destinationManagementState,
        sortModel: action.sortModel,
      },
    };
  } else if (action.type === ActionType.SetGlobalSearchSelectedTags) {
    return {
      ...state,
      globalSearchState: {
        ...state.globalSearchState,
        selectedTags: action.selectedTags,
      },
    };
  } else if (action.type === ActionType.SetLoginRedirectUrl) {
    return {
      ...state,
      redirectUrl: action.redirectUrl,
    };
  } else if (action.type === ActionType.SetPlanBreadcrumb) {
    return {
      ...state,
      planBreadcrumb: action.breadcrumb,
    };
  } else if (action.type === LOCATION_CHANGE) {
    const pathname = action.payload.location.pathname;
    const isOnDashboard = getViewPathMatch(pathname);
    const isInPlanning = getPlanningTypeMatch(pathname);
    const returnTo = !!isInPlanning || !!isOnDashboard ? state.returnTo : null;

    return {
      ...state,
      returnTo,
      referrer: state.currentUrl,
      currentUrl: toHref(action.payload.location),
    };
  } else if (action.type === ActionType.SetReturnTo) {
    return {
      ...state,
      returnTo: action.returnTo,
    };
  } else if (action.type === ActionType.SetIntegrationsPageFilterText) {
    return {
      ...state,
      integrationsPageSearchState: {
        ...state.integrationsPageSearchState,
        filterText: action.filterText,
      },
    };
  } else if (action.type === ActionType.SetIntegrationsPageCountriesFilter) {
    return {
      ...state,
      integrationsPageSearchState: {
        ...state.integrationsPageSearchState,
        selectedCountries: action.selectedValues,
      },
    };
  } else if (action.type === ActionType.SetIntegrationsPageTypesFilter) {
    return {
      ...state,
      integrationsPageSearchState: {
        ...state.integrationsPageSearchState,
        selectedTypes: action.selectedValues,
      },
    };
  } else if (action.type === ActionType.SetIntegrationsPageTagsFilter) {
    return {
      ...state,
      integrationsPageSearchState: {
        ...state.integrationsPageSearchState,
        selectedTags: action.selectedValues,
      },
    };
  } else if (action.type === ActionType.SetIntegrationsPageScrollPosition) {
    return {
      ...state,
      integrationsPageSearchState: {
        ...state.integrationsPageSearchState,
        scrollPosition: action.value,
      },
    };
  } else if (action.type === ActionType.SetIntegrationsPageIsAddingDataSource) {
    return {
      ...state,
      integrationsPageSearchState: {
        ...state.integrationsPageSearchState,
        isAddingDataSource: action.isAddingDataSource,
      },
    };
  } else if (action.type === ActionType.SetNavigationInProgress) {
    return {...state, isNavigationInProgress: action.inProgress};
  }
  return state;
}
