import React from 'react';

import ErrorBoundary from 'toolkit/components/ErrorBoundary';
import Loader from 'toolkit/components/Loader';
import ModalDialog, {ModalDialogProps} from 'toolkit/components/ModalDialog';

const Boundary: React.FC<Props> = ({
  children,
  renderError,
  fallback = <Loader className="boundary-loader" />,
}) => {
  return (
    <React.Suspense fallback={fallback}>
      <ErrorBoundary renderError={renderError}>{children}</ErrorBoundary>
    </React.Suspense>
  );
};

export default Boundary;

type Props = {
  children: React.ReactNode;
  fallback?: React.ReactNode;
  renderError?: (renderedError: React.ReactNode, error: Error) => React.ReactNode;
};

export function withBoundary<P extends object>(
  Component: React.ComponentType<P>,
  boundaryProps?: Props
) {
  return (props: P) => (
    <Boundary {...boundaryProps}>
      <Component {...props} />
    </Boundary>
  );
}

type ModalBoundaryProps = Pick<
  ModalDialogProps,
  'size' | 'isVerticallyCentered' | 'dialogClassName' | 'onClose'
>;

export const ModalBoundary: React.FC<React.PropsWithChildren<ModalBoundaryProps>> = ({
  size,
  isVerticallyCentered,
  dialogClassName,
  onClose,
  children,
} = {}) => {
  const modalProps = {
    dialogClassName,
    isVerticallyCentered,
    isFooterVisible: false,
    size,
    title: '',
    onClose,
  };
  const renderError = (content: React.ReactNode) => (
    <ModalDialog {...modalProps}>{content}</ModalDialog>
  );
  return (
    <Boundary
      fallback={
        <ModalDialog {...modalProps}>
          <Loader />
        </ModalDialog>
      }
      renderError={renderError}
    >
      {children}
    </Boundary>
  );
};

export function withModalBoundary<P extends {onClose: ModalDialogProps['onClose']}>(
  Component: React.ComponentType<P>,
  {size, isVerticallyCentered, dialogClassName}: ModalBoundaryProps = {}
): React.FC<P> {
  return (props: P) => {
    const modalProps = {
      dialogClassName,
      isVerticallyCentered,
      size,
      onClose: props.onClose,
    };
    return (
      <ModalBoundary {...modalProps}>
        <Component {...props} />
      </ModalBoundary>
    );
  };
}
