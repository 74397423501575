export enum Status {
  unstarted = 'unstarted',
  inProgress = 'inProgress',
  succeeded = 'succeeded',
  failed = 'failed',
}

export enum BinaryStatus {
  succeeded = 'succeeded',
  failed = 'failed',
}

export enum PromiseStatus {
  success = 'success',
  failure = 'failure',
  request = 'request',
}

export enum TimelineStatus {
  unstarted = 'unstarted',
  succeeded = 'succeeded',
  partial = 'partial',
  inProgress = 'inProgress',
  failed = 'failed',
  notExpected = 'notExpected',
}

export function getCombinedStatus(...statuses: readonly Status[]) {
  if (statuses.some(status => status === Status.failed)) {
    return Status.failed;
  } else if (statuses.some(status => status === Status.inProgress)) {
    return Status.inProgress;
  } else if (statuses.some(status => status === Status.unstarted)) {
    return Status.unstarted;
  }
  return Status.succeeded;
}
