import {Range, Set} from 'immutable';

import {ComputeResultData, TypedRowNode} from 'toolkit/ag-grid/types';
import {getNodesToRoot} from 'toolkit/ag-grid/utils';
import {FILTERS_TO_REMOVE_FOR_ATTRIBUTE_LINKS, fromThinValue} from 'toolkit/attributes/utils';
import {ThinComputeResultRowExtended} from 'toolkit/compute/types';
import {createFilter} from 'toolkit/filters/utils';
import * as Types from 'types';
import {assertTruthy} from 'utils/assert';
import {isTruthy} from 'utils/functions';

import {TableSelectionAttributeValue} from './types';

export function isSelectableGrouping(grouping: Types.GroupingAttribute | null) {
  return (
    grouping &&
    grouping.attribute?.valueType !== Types.AttributeValueType.date &&
    grouping.attribute?.valueType !== Types.AttributeValueType.interval
  );
}

export function getLevelFromData(data: ComputeResultData) {
  return data && data.data ? data.level : 0;
}

export function getFlattenedTableSelectionFilters(
  rowGroupings: readonly Types.AttributeInstance[],
  values: readonly Types.ThinAttributeValue[]
) {
  return getFlattenedTableAttributeValues(rowGroupings, values)
    .map(createSelectionFilterFromAttributeValue)
    .filter(isTruthy);
}

export function getGroupedTableSelectionFilters(
  rowGroupings: readonly Types.AttributeInstance[],
  node: TypedRowNode<ComputeResultData>
) {
  return getGroupedTableAttributeValues(rowGroupings, node)
    .map(createSelectionFilterFromAttributeValue)
    .filter(isTruthy);
}

export function getFlattenedTableAttributeValues(
  rowGroupings: readonly Types.AttributeInstance[],
  values: readonly Types.ThinAttributeValue[]
): readonly TableSelectionAttributeValue[] {
  return rowGroupings.map((attributeInstance, index) => {
    const attributeValue = fromThinValue(values[index], attributeInstance.attribute);
    return {attributeInstance, attributeValue};
  });
}

export function getGroupedTableAttributeValues(
  rowGroupings: readonly Types.AttributeInstance[],
  node: TypedRowNode<ComputeResultData>
): readonly TableSelectionAttributeValue[] {
  if (rowGroupings.length === 0) {
    return [];
  }
  return getNodesToRoot(node)
    .reverse()
    .map((nodeInPath, index) => {
      const attributeInstance = rowGroupings[index];
      return {
        attributeInstance,
        attributeValue: fromThinValue(
          assertTruthy(nodeInPath.data.data.value),
          attributeInstance.attribute
        ),
      };
    })
    .toArray();
}

export function createSelectionFilterFromAttributeValue({
  attributeInstance,
  attributeValue,
}: TableSelectionAttributeValue) {
  return isSelectableGrouping(attributeInstance)
    ? createFilter(attributeInstance, [attributeValue])
    : undefined;
}

function getFlattenedSelectionFilters(
  row: ThinComputeResultRowExtended,
  attributeInstances: readonly Types.AttributeInstance[],
  level: number
) {
  if (
    attributeInstances.some(
      attributeInstance => attributeInstance.attribute.type === Types.AttributeType.DATE
    )
  ) {
    return [];
  }
  return Range(0, level + 1)
    .map(index => {
      const attributeInstance = attributeInstances[index];
      return createFilter(attributeInstance, [
        assertTruthy(fromThinValue(row.values[index], attributeInstance.attribute)),
      ]);
    })
    .toArray();
}

function getSelectionFiltersAtLevel(
  data: ComputeResultData,
  node: TypedRowNode<ComputeResultData>,
  attributeInstances: readonly Types.AttributeInstance[],
  level: number,
  isFlattened: boolean,
  columnGroupings: readonly Types.AttributeInstance[],
  columnPath: readonly Types.ThinAttributeValue[]
) {
  if (
    columnGroupings
      .slice(0, columnPath.length)
      .some(attributeInstance => attributeInstance.attribute.type === Types.AttributeType.DATE)
  ) {
    return [];
  }
  return [
    ...(isFlattened
      ? getFlattenedSelectionFilters(data.data, attributeInstances, level)
      : getGroupedTableSelectionFilters(attributeInstances, node)),
    ...columnPath.map((columnValue, index) => {
      const attributeInstance = columnGroupings[index];
      return createFilter(attributeInstance, [
        assertTruthy(fromThinValue(columnValue, attributeInstance.attribute)),
      ]);
    }),
  ];
}

export function getLinkSelectionFilters(
  viewFilters: readonly Types.AttributeFilter[],
  data: ComputeResultData,
  node: TypedRowNode<ComputeResultData>,
  rowAttributeInstances: readonly Types.AttributeInstance[],
  level: number,
  isFlattened: boolean,
  columnGroupings: readonly Types.AttributeInstance[],
  columnPath: readonly Types.ThinAttributeValue[]
) {
  const attributeInstance = rowAttributeInstances[level];
  const filters =
    rowAttributeInstances.length || columnPath.length
      ? getSelectionFiltersAtLevel(
          data,
          node,
          rowAttributeInstances,
          level,
          isFlattened,
          columnGroupings,
          columnPath
        )
      : [];
  if (!filters.length) {
    return [];
  }
  const filtersToRemove = Set(
    filters.map(filter => filter.attributeInstance.attribute.name)
  ).concat(FILTERS_TO_REMOVE_FOR_ATTRIBUTE_LINKS.get(attributeInstance?.attribute?.name) || Set());

  return viewFilters
    .filter(filter => !filtersToRemove.contains(filter.attributeInstance.attribute.name))
    .concat(filters);
}
