import './GlobalSearchDropdownContent.scss';

import Fuse from 'fuse.js';
import {Map as ImmutableMap, Set} from 'immutable';
import React, {useMemo, useCallback} from 'react';

import * as Api from 'api';
import {getDataPageSidebarOptions} from 'data/getDataPageTabOptions';
import {storeAndGoToNewView} from 'redux/actions/analysis';
import {
  setGlobalSearchFilterText,
  setGlobalSearchSelectedTags,
  setDashboardManagementFilterText,
  setDashboardManagementActiveFilter,
} from 'redux/actions/navigation';
import {useCurrentUser} from 'redux/context/user';
import {CurrentUser} from 'redux/reducers/user';
import {getAllMetricsWithAnyData, getVisibleThinViews} from 'redux/selectors/analysis';
import useSelector from 'redux/selectors/useSelector';
import useDispatch from 'redux/useDispatch';
import {getAvailableSettingsGroups} from 'settings/utils';
import {
  AnalysisEntity,
  AnalysisEntityType,
  DashboardAnalysisEntity,
  PageAnalysisEntity,
} from 'toolkit/analysis/types';
import {PartnersById} from 'toolkit/attributes/types';
import Icon from 'toolkit/components/Icon';
import {ALL_SELECTED} from 'toolkit/entities/types';
import Format from 'toolkit/format/format';
import {getExperimentAnalysisEntities} from 'toolkit/groups/utils';
import TagsList from 'toolkit/tags/TagsList';
import {useSortedViewTagNames} from 'toolkit/tags/utils';
import {hasPermission, isAtLeast} from 'toolkit/users/utils';
import {handleItemKeyNavigation} from 'toolkit/utils/events';
import {ANALYSIS_ENTITY_FUSE_OPTIONS, DEFAULT_FUSE_OPTIONS} from 'toolkit/utils/input';
import {
  getRecentViews,
  toAnalysisEntity,
  getDashboardAnalysisEntities,
  getTemplateAnalysisEntities,
  getIntelligenceManagementPageUrl,
} from 'toolkit/views/utils';
import * as Types from 'types';
import {isTruthy, noop} from 'utils/functions';
import {useApi, useResult} from 'utils/useApi';
import {getAccessibleWorkflows, getWorkflowUrl} from 'workflows/utils';

import {getAskAlloyEntities} from './ask-alloy';
import GlobalSearchEntityResultsGroup from './GlobalSearchEntityResultsGroup';
import GlobalSearchResultsSection from './GlobalSearchResultsSection';
import {SearchResultsProvider, SearchResultsProviderResult} from './types';
import {
  getGlobalSearchContentElement,
  GLOBAL_SEARCH_BAR_ID,
  GLOBAL_SEARCH_CONTENT_ID,
  GLOBAL_SEARCH_ITEM_CLASSNAME,
} from './utils';

const MAX_RECENTS = 10;
const MAX_RESULTS_PER_SECTION = 5;
const MAX_TOTAL_RESULTS = 11;

export function focusFirstSearchElement() {
  const firstSearchElement = getFirstSearchElement();
  firstSearchElement?.focus();
  return firstSearchElement;
}

function getFirstSearchElement() {
  const content = document.getElementById(GLOBAL_SEARCH_CONTENT_ID);
  const items = content!.getElementsByClassName(GLOBAL_SEARCH_ITEM_CLASSNAME);
  if (items.length > 0) {
    return items[0] as HTMLElement;
  }
  return null;
}

function createEntityFuse<T extends AnalysisEntity>(entities: readonly T[]) {
  return new Fuse(entities, {
    ...ANALYSIS_ENTITY_FUSE_OPTIONS,
    keys: ['name', 'displayName', 'keywords'],
  });
}

function createAskAlloyResultsProvider<
  T extends AnalysisEntity = DashboardAnalysisEntity<Types.View>,
>(
  entities: readonly T[],
  title: string,
  onResultClick: (entity: T | null) => void
): SearchResultsProvider<T> {
  return {
    entities,
    onResultClick,
    title,
  };
}

function createSearchResultsProvider<T extends AnalysisEntity>(
  entities: readonly T[],
  title: string,
  moreUrl?: string,
  onMoreClick?: () => void
): SearchResultsProvider<T> {
  return {
    entities,
    fuse: createEntityFuse(entities),
    moreUrl,
    onMoreClick,
    title,
  };
}

function search<T extends AnalysisEntity>(provider: SearchResultsProvider<T>, filterText: string) {
  // TODO: Refactor such that this method exists inside the provider (sc-59848)
  return filterText && !!provider.fuse
    ? provider.fuse.search(filterText).map(result => result.item)
    : provider.entities;
}

function filterBySelectedTags(
  entities: readonly AnalysisEntity[],
  selectedTags: readonly string[]
) {
  return entities.filter(entity => {
    return selectedTags.every(matchingTag => entity.tags.includes(matchingTag));
  });
}

function getDataEntities(user: CurrentUser): readonly PageAnalysisEntity[] {
  return getDataPageSidebarOptions(user).flatMap(group =>
    group.items.map(item => ({
      id: null,
      isFavorite: false,
      icon: 'database',
      lastVisited: null,
      isPrivate: false,
      ownerId: null,
      tags: [],
      type: AnalysisEntityType.PAGE,
      name: item.displayName,
      keywords: item.keywords,
      url: `/${user.vendor.name}/${item.name}`,
    }))
  );
}

function getSettingsEntities(user: CurrentUser, partners: PartnersById) {
  return getAvailableSettingsGroups(user, partners).flatMap(group =>
    group.items.map<AnalysisEntity>(item => ({
      id: null,
      isFavorite: false,
      icon: 'cog',
      lastVisited: null,
      isPrivate: group.requiredRole && isAtLeast(group.requiredRole, Types.Role.ADMIN),
      name: item.displayName,
      ownerId: null,
      tags: [],
      type: AnalysisEntityType.PAGE,
      value: null,
      keywords: item.keywords,
      url: `/${user.vendor.name}/${item.name}`,
    }))
  );
}

function getWorkflowEntities(user: CurrentUser, partners: PartnersById) {
  return getAccessibleWorkflows(user, partners).map<AnalysisEntity>(workflow => ({
    id: null,
    isFavorite: false,
    icon: 'stream',
    lastVisited: null,
    isPrivate: false,
    name: Format.workflow(workflow),
    ownerId: null,
    tags: [],
    type: AnalysisEntityType.PAGE,
    value: null,
    url: getWorkflowUrl(workflow, user.vendor.name),
  }));
}

const GlobalSearchDropdownContent: React.FC<Props> = ({
  onFocusSearchRequested,
  onResultClick,
  onTagClick,
}) => {
  const currentUser = useCurrentUser();
  const dispatch = useDispatch();
  const allAttributes = useSelector(state => state.analysis.data.allGroupings);
  const settings = useCurrentUser().settings;
  const availableMetrics = useSelector(getAllMetricsWithAnyData);
  const defaultAttributeHierarchies = useSelector(
    state => state.analysis.data.defaultAttributeHierarchies
  );

  const filterText = useSelector(state => state.navigation.globalSearchState.filterText);
  const selectedTags = useSelector(state => state.navigation.globalSearchState.selectedTags);
  const experiments = useSelector(state => state.groups.experiments);
  const experimentVisitedTimes = useSelector(state => state.groups.visitedTimestampsForUser);
  const favoriteExperimentIds = useSelector(state => state.groups.favoriteExperimentIds);
  const favoriteViewIds = useSelector(state => state.analysis.data.favoriteViews);
  const partners = useSelector(state => state.analysis.data.partners);
  const availableTagNames = useSortedViewTagNames();
  const availableViewsById = useSelector(getVisibleThinViews);

  const currentPathname = useSelector(state => state.router.location.pathname);
  const viewTimestamps = useResult(useApi(Api.Views.getVisitedViewTimestampsForUser.getResource()));
  const viewTimestampsForUser = useMemo(
    () => ImmutableMap(viewTimestamps.map(timestamp => [timestamp.entityId, timestamp.visited])),
    [viewTimestamps]
  );

  const trimmedFilterText = filterText.trim();
  const isSearching = !!trimmedFilterText || selectedTags.length > 0;

  const metricsFuse = useMemo(
    () =>
      new Fuse(
        Array.from(availableMetrics.values()).filter(metric =>
          metric.features.every(
            feature =>
              feature !== Types.MetricFeature.IS_HIGHER_ORDER &&
              feature !== Types.MetricFeature.IS_HIDDEN
          )
        ),
        {
          ...DEFAULT_FUSE_OPTIONS,
          keys: ['name', 'displayName'],
        }
      ),
    [availableMetrics]
  );
  const partnersFuse = useMemo(() => {
    const allPartnersPartner: Types.Partner = {
      id: null,
      name: 'All Partners',
      vendorId: null,
      defaultCalendar: null,
      isSyndicatedDataSource: false,
      type: Types.PartnerType.UNKNOWN,
    };
    const withAllPartnersPartner = [...Array.from(partners.values()), allPartnersPartner];
    return new Fuse(withAllPartnersPartner, {
      ...DEFAULT_FUSE_OPTIONS,
      keys: ['name'],
    });
  }, [partners]);

  const attributesFuse = useMemo(
    () =>
      new Fuse(Array.from(allAttributes.values()), {
        ...DEFAULT_FUSE_OPTIONS,
        keys: ['name'],
      }),
    [allAttributes]
  );

  const askAlloyEntities: ReadonlyArray<DashboardAnalysisEntity<Types.View>> = useMemo(
    () =>
      getAskAlloyEntities(
        currentUser,
        settings,
        allAttributes,
        availableMetrics,
        defaultAttributeHierarchies,
        currentUser.vendor.name,
        metricsFuse,
        partnersFuse,
        attributesFuse,
        trimmedFilterText
      ),
    // disabled to avoid adding dependencies that may take a while to load,
    // leading to observable content jumps without any change in filter text
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser.user, currentUser.vendor.name, trimmedFilterText, allAttributes.size]
  );

  const dashboardEntities: readonly AnalysisEntity[] = useMemo(
    () =>
      hasPermission(currentUser, Types.PermissionKey.DASHBOARD_VIEW_DASHBOARD)
        ? getDashboardAnalysisEntities(
            availableViewsById,
            favoriteViewIds,
            viewTimestampsForUser,
            currentUser
          )
        : [],
    [availableViewsById, currentUser, favoriteViewIds, viewTimestampsForUser]
  );
  const experimentEntities: readonly AnalysisEntity[] = useMemo(
    () =>
      hasPermission(currentUser, Types.PermissionKey.EXPERIMENT_VIEW_EXPERIMENT)
        ? getExperimentAnalysisEntities(
            experiments,
            experimentVisitedTimes,
            favoriteExperimentIds,
            currentUser.vendor.name
          )
        : [],
    [currentUser, experimentVisitedTimes, experiments, favoriteExperimentIds]
  );
  const templateEntities: readonly AnalysisEntity[] = useMemo(
    () =>
      getTemplateAnalysisEntities(
        availableViewsById,
        favoriteViewIds,
        viewTimestampsForUser,
        currentUser
      ),
    [availableViewsById, currentUser, favoriteViewIds, viewTimestampsForUser]
  );

  // +1 because we might want to filter the current dashboard away
  const recents: readonly AnalysisEntity[] = useMemo(
    () =>
      getRecentViews(currentUser, availableViewsById, viewTimestampsForUser, MAX_RECENTS + 1)
        .map(view => toAnalysisEntity(view, currentUser.vendor.name, Set(), viewTimestampsForUser))
        .concat(experimentEntities)
        .filter(entity => !entity.url.startsWith(currentPathname))
        .sortBy(entity => (entity.lastVisited ? entity.lastVisited : ''))
        .reverse()
        .slice(0, MAX_RECENTS)
        .toArray(),
    [currentUser, availableViewsById, viewTimestampsForUser, experimentEntities, currentPathname]
  );

  const handleAskAlloyResultClick = useCallback(
    (entity: DashboardAnalysisEntity<Types.View> | null) => {
      onResultClick(entity);
      dispatch(storeAndGoToNewView({...entity!.value, id: null}));
    },
    [dispatch, onResultClick]
  );

  const handleTagClick = (tag: string) => {
    dispatch(setGlobalSearchFilterText(''));
    dispatch(setGlobalSearchSelectedTags([...selectedTags, tag]));
    onTagClick();
  };

  const handleMoreAnalysisContentClick = useCallback(
    (analysisEntityType: AnalysisEntityType | null) => {
      dispatch(setDashboardManagementFilterText(trimmedFilterText));
      dispatch(setGlobalSearchFilterText(''));
      dispatch(setGlobalSearchSelectedTags([]));
      if (analysisEntityType) {
        const filterValue =
          analysisEntityType === AnalysisEntityType.DASHBOARD
            ? 'dashboards'
            : analysisEntityType === AnalysisEntityType.TEMPLATE
              ? 'templates'
              : 'experiments';
        dispatch(setDashboardManagementActiveFilter('type', [filterValue]));
      } else if (selectedTags.length > 0) {
        dispatch(setDashboardManagementActiveFilter('tags', selectedTags));
      } else {
        dispatch(setDashboardManagementActiveFilter('type', ALL_SELECTED));
      }
      onResultClick(null);
    },
    [dispatch, onResultClick, selectedTags, trimmedFilterText]
  );

  const handleSpecialKeys = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Tab' && event.shiftKey && event.target === getFirstSearchElement()) {
      const inputs = document.getElementById(GLOBAL_SEARCH_BAR_ID)!.getElementsByTagName('input');
      if (inputs.length > 0) {
        event.preventDefault();
        inputs[0].focus();
      }
    }
  }, []);

  const tagsFuse = useMemo(
    () => new Fuse(availableTagNames, DEFAULT_FUSE_OPTIONS),
    [availableTagNames]
  );
  const matchingTags = (
    !!trimmedFilterText
      ? tagsFuse.search(trimmedFilterText).map(({refIndex}) => availableTagNames[refIndex])
      : []
  ).filter(tag => !selectedTags.includes(tag));

  const analysisManagementUrl = getIntelligenceManagementPageUrl(currentUser.vendor.name);

  const handleMoreClick = useCallback(
    (callback: () => void) => {
      callback();
      onResultClick(null);
    },
    [onResultClick]
  );

  const askAlloyResultsProvider = useMemo(
    () =>
      createAskAlloyResultsProvider(askAlloyEntities, 'Ask Alloy.ai', handleAskAlloyResultClick),
    [askAlloyEntities, handleAskAlloyResultClick]
  );

  const searchResultProviders: ReadonlyArray<SearchResultsProvider<AnalysisEntity>> =
    useMemo(() => {
      const defaultMoreClickHandler = () => handleMoreClick(noop);
      return [
        createSearchResultsProvider(dashboardEntities, 'Dashboards', analysisManagementUrl, () =>
          handleMoreClick(() => handleMoreAnalysisContentClick(AnalysisEntityType.DASHBOARD))
        ),
        createSearchResultsProvider(experimentEntities, 'Experiments', analysisManagementUrl, () =>
          handleMoreClick(() => handleMoreAnalysisContentClick(AnalysisEntityType.EXPERIMENT))
        ),
        createSearchResultsProvider(templateEntities, 'Templates', analysisManagementUrl, () =>
          handleMoreClick(() => handleMoreAnalysisContentClick(AnalysisEntityType.TEMPLATE))
        ),
        createSearchResultsProvider<AnalysisEntity>(
          getWorkflowEntities(currentUser, partners),
          'Workflows',
          analysisManagementUrl,
          defaultMoreClickHandler
        ),
        createSearchResultsProvider<AnalysisEntity>(
          getDataEntities(currentUser),
          'Data',
          `/${currentUser.vendor.name}/data`,
          defaultMoreClickHandler
        ),
        createSearchResultsProvider<AnalysisEntity>(
          getSettingsEntities(currentUser, partners),
          'Settings',
          `/${currentUser.vendor.name}/settings`,
          defaultMoreClickHandler
        ),
      ];
    }, [
      dashboardEntities,
      analysisManagementUrl,
      experimentEntities,
      templateEntities,
      currentUser,
      handleMoreClick,
      handleMoreAnalysisContentClick,
      partners,
    ]);

  const askAlloyResults: SearchResultsProviderResult<DashboardAnalysisEntity<Types.View>> = {
    provider: askAlloyResultsProvider,
    matches: askAlloyResultsProvider.entities,
  };

  const searchResults: ReadonlyArray<SearchResultsProviderResult<AnalysisEntity>> =
    searchResultProviders.map(provider => ({
      provider,
      matches: filterBySelectedTags(search(provider, trimmedFilterText), selectedTags),
    }));

  const showExploreAlloy =
    !!trimmedFilterText &&
    !matchingTags.length &&
    !searchResults.some(result => result.matches.length);
  const showNoResultsFound = showExploreAlloy && !askAlloyResults.matches.length;

  let totalResultCount = askAlloyResults.matches.length; // eslint-disable-line fp/no-let

  const resultGroups = isSearching
    ? searchResults
        .map((result, index) => {
          const maxResultsPerSection = Math.min(
            MAX_RESULTS_PER_SECTION,
            Math.max(2, Math.floor((MAX_TOTAL_RESULTS - totalResultCount) / 2))
          );
          totalResultCount += Math.min(result.matches.length, maxResultsPerSection);

          return totalResultCount <= MAX_TOTAL_RESULTS ? (
            <GlobalSearchEntityResultsGroup
              key={`result-group-${index}`}
              matches={result.matches}
              maxResultsPerSection={maxResultsPerSection}
              moreUrl={result.provider.moreUrl}
              title={result.provider.title}
              onFocusSearchRequested={onFocusSearchRequested}
              onMoreClick={result.provider.onMoreClick}
              onResultClick={result.provider.onResultClick || onResultClick}
            />
          ) : null;
        })
        .filter(isTruthy)
    : [];

  return (
    <div
      className="GlobalSearchDropdownContent"
      id={GLOBAL_SEARCH_CONTENT_ID}
      onKeyDown={handleSpecialKeys}
    >
      <div className="result-list">
        {matchingTags.length > 0 && selectedTags.length === 0 && (
          <GlobalSearchResultsSection contentClassName="tags" title="Tags">
            <TagsList
              value={matchingTags}
              onItemClick={handleTagClick}
              onKeyDown={event =>
                handleItemKeyNavigation(
                  event,
                  GLOBAL_SEARCH_ITEM_CLASSNAME,
                  onFocusSearchRequested,
                  getGlobalSearchContentElement
                )
              }
            />
          </GlobalSearchResultsSection>
        )}
        <GlobalSearchEntityResultsGroup
          contentClassName="AskAlloyResults"
          matches={askAlloyResultsProvider.entities}
          title={askAlloyResultsProvider.title}
          onFocusSearchRequested={onFocusSearchRequested}
          onResultClick={askAlloyResultsProvider.onResultClick!}
        />
        {!isSearching && (
          <GlobalSearchEntityResultsGroup
            matches={recents}
            maxResultsPerSection={MAX_RECENTS}
            moreUrl={analysisManagementUrl}
            title="Recents"
            onFocusSearchRequested={onFocusSearchRequested}
            onMoreClick={() => handleMoreAnalysisContentClick(null)}
            onResultClick={onResultClick}
          />
        )}
        {resultGroups}
        {showNoResultsFound && (
          <GlobalSearchResultsSection contentClassName="plaintext">
            No results found for <b>{trimmedFilterText}</b>.
          </GlobalSearchResultsSection>
        )}
        {showExploreAlloy && selectedTags.length === 0 && (
          <GlobalSearchResultsSection contentClassName="explore" title="Explore Alloy.ai">
            <Icon icon="tag" />
            <TagsList
              value={availableTagNames}
              multiline
              onItemClick={handleTagClick}
              onKeyDown={event =>
                handleItemKeyNavigation(
                  event,
                  GLOBAL_SEARCH_ITEM_CLASSNAME,
                  onFocusSearchRequested,
                  getGlobalSearchContentElement
                )
              }
            />
          </GlobalSearchResultsSection>
        )}
      </div>
    </div>
  );
};

GlobalSearchDropdownContent.displayName = 'GlobalSearchDropdownContent';

interface Props {
  onFocusSearchRequested: () => void;
  onResultClick: (entity: AnalysisEntity | null) => void;
  onTagClick: () => void;
}

export default GlobalSearchDropdownContent;
