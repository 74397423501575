import {Map, Set} from 'immutable';
import moment from 'moment-timezone';
import {match as RouterMatch} from 'react-router';

import {CurrentUser} from 'redux/reducers/user';
import {AttributesByTypeAndName} from 'toolkit/attributes/types';
import {getAttributeNameForGranularity, toThinAttributeValue} from 'toolkit/attributes/utils';
import {createFilter} from 'toolkit/filters/utils';
import {DATE_FORMAT} from 'toolkit/format/constants';
import Format from 'toolkit/format/format';
import {MetricsByName} from 'toolkit/metrics/types';
import {
  getMetricArgumentsFromSettingsAndPlanVersion,
  getMetricOrThrow,
} from 'toolkit/metrics/utils';
import {getVendorEvaluationDate} from 'toolkit/time/utils';
import * as Types from 'types';
import {ForecastComposition, NewProductType} from 'types';
import {ArrayTree, buildArrayTree} from 'utils/array-trees';
import {maxBy} from 'utils/arrays';
import {assertTruthy} from 'utils/assert';
import {isTruthy} from 'utils/functions';
import {ComputableWidget} from 'widgets/types';
import {getDefaultWidgetProperties} from 'widgets/utils';

const NEW_PRODUCT_CONFIG_GRANULARITY = Types.CalendarUnit.WEEKS;

const ADOPTION_CHART_WIDGET_ID = -1;

const [
  UNSAVABLE_NEW_PRODUCT_ID,
  UNSAVABLE_TRANSITION_PRODUCT_ID,
  UNSAVABLE_COMPARABLE_PRODUCT_ID,
  UNSAVABLE_COMPARABLE_TRANSITION_PRODUCT_ID,
] = [-1, -2, -3, -4];

export interface DefaultNPIConfigurationProps {
  filters: readonly Types.AttributeFilter[];
  match: RouterMatch<any>;
  npiConfig: Types.NewProductIntroductionConfig;
  npiSettings: Types.NewProductSettings;
  onChange: (config: Types.NewProductIntroductionConfig) => void;
  planHierarchy: readonly Types.Attribute[];
  originalPlanVersion: Types.PlanVersion;
}

export enum PendingNpiStatus {
  UNCHANGED,
  PENDING_ARCHIVE,
  PENDING_UNARCHIVE,
}
export type PendingNpiStatusById = Map<number, PendingNpiStatus>;

function getNpiSubtypeError(
  npi: Types.NewProductIntroduction,
  planHierarchy: readonly Types.Attribute[]
): string | null {
  switch (npi.newProductType) {
    case Types.NewProductType.TRANSITION_PRODUCT:
      if (npi.comparableAttributeValues.length === planHierarchy.length) {
        return 'Transition products must fully specify the product that is being transitioned';
      }
      return null;
    default:
      return null;
  }
}

export function getNpiError(
  npi: Types.NewProductIntroduction,
  planHierarchy: readonly Types.Attribute[]
): string | null {
  if (npi?.comparableAttributeValues.length === 0) {
    return 'Comparison product/category is required';
  } else if (!npi.startDate) {
    return 'Start date is required';
  } else if (!npi.endDate) {
    return 'End date is required';
  } else if (!npi.curve) {
    return 'Adoption Rate is required';
  } else if (npi.scaleFactor <= 0) {
    return 'Scale factor must be > 0%';
  } else if (npi.attributeValues.length < planHierarchy.length) {
    return 'New product path is not fully specified.';
  } else if (!moment(npi.startDate).isBefore(moment(npi.endDate))) {
    return 'Start date must be before end date';
  }

  return null;
}

export function isNpiValid(
  npi: Types.NewProductIntroduction,
  planHierarchy: readonly Types.Attribute[]
): boolean {
  const error = getNpiError(npi, planHierarchy);

  return !error && !getNpiSubtypeError(npi, planHierarchy);
}

export function getNpiTypeDisplayName(npi: Types.NewProductIntroduction): string {
  switch (npi.newProductType) {
    case Types.NewProductType.NEW_PRODUCT:
      return 'New Product';
    case Types.NewProductType.TRANSITION_PRODUCT:
      return 'Transition Product';
    default:
      throw new Error(`Invalid NPI type: ${npi.newProductType}`);
  }
}

export function getNpiTitle(
  planHierarchy: readonly Types.Attribute[],
  npiConfig: Types.NewProductIntroductionConfig
) {
  return planHierarchy
    .map(attribute => {
      const value = npiConfig.newProductIntroduction.attributeValues.find(
        value => value.attribute.id === attribute.id
      );
      return value && Format.attributeValue(value);
    })
    .filter(isTruthy)
    .join(' / ');
}

export function getNpiPath(
  npiProductPath: readonly Types.AttributeValue[],
  planHierarchy: readonly Types.Attribute[]
): readonly Types.ThinAttributeValue[] {
  return planHierarchy
    .map(attribute => npiProductPath.find(value => value.attribute.id === attribute.id))
    .filter(isTruthy)
    .map(attrValue => toThinAttributeValue(attrValue));
}

export function getPathFilters(
  comparableAttributeValues: readonly Types.AttributeValue[]
): readonly Types.AttributeFilter[] {
  return comparableAttributeValues.map(value => createFilter(value.attribute, [value]));
}

export function getAdoptionMetrics(
  availableMetrics: MetricsByName,
  currentUser: CurrentUser,
  startDate: string,
  endDate: string,
  newProductIntroduction: Types.NewProductIntroduction,
  planVersion: Types.PlanVersion,
  showComparableMetric: boolean
): readonly Types.MetricInstance[] {
  const newProduct = getGivenIntroduction(newProductIntroduction, false);
  const comparableProduct = getGivenIntroduction(newProductIntroduction, true);
  return [
    {
      metric: {
        ...getMetricOrThrow('forecast_sales_units_net', availableMetrics),
        displayName:
          getDisplayName(newProduct.newProductType) ??
          getMetricOrThrow('forecast_sales_units_net', availableMetrics).displayName,
      },
      arguments: {
        ...getMetricArgumentsFromSettingsAndPlanVersion(currentUser.settings, planVersion),
        versionRecency: null,
        period: {type: 'fixed', start: startDate, end: endDate},
      },
    },
    ...(showComparableMetric
      ? [
          {
            metric: {
              ...getMetricOrThrow('forecast_sales_units_net', availableMetrics),
              displayName:
                getDisplayName(comparableProduct.newProductType) ??
                getMetricOrThrow('forecast_sales_units_net', availableMetrics).displayName,
            },
            arguments: {
              ...getMetricArgumentsFromSettingsAndPlanVersion(currentUser.settings, planVersion),
              versionRecency: null,
              period: {type: 'fixed', start: startDate, end: endDate} as Types.DatePeriod,
              forecastType: Types.ForecastType.PLAN,
              forecastComposition: ForecastComposition.TOTAL,
            },
          },
        ]
      : []),
  ];
}

function getDisplayName(newProductType: Types.NewProductType) {
  switch (newProductType) {
    case Types.NewProductType.COMPARABLE_PRODUCT:
    case Types.NewProductType.COMPARABLE_TRANSITION_PRODUCT:
      return 'Comparison Product Forecast';
    case Types.NewProductType.NEW_PRODUCT:
    case Types.NewProductType.TRANSITION_PRODUCT:
      return 'New Product Forecast';
    default:
      return null;
  }
}

export function getGivenIntroduction(
  newProductIntroduction: Types.NewProductIntroduction,
  isComparable: boolean
): Types.NewProductIntroduction {
  const isTransitionProduct =
    newProductIntroduction.newProductType === Types.NewProductType.TRANSITION_PRODUCT;
  if (!isComparable) {
    if (newProductIntroduction.id) {
      return newProductIntroduction;
    }
    return {
      ...newProductIntroduction,
      id: isTransitionProduct ? UNSAVABLE_TRANSITION_PRODUCT_ID : UNSAVABLE_NEW_PRODUCT_ID,
    };
  }
  return {
    ...newProductIntroduction,
    id: isTransitionProduct
      ? UNSAVABLE_COMPARABLE_TRANSITION_PRODUCT_ID
      : UNSAVABLE_COMPARABLE_PRODUCT_ID,
    newProductType: isTransitionProduct
      ? Types.NewProductType.COMPARABLE_TRANSITION_PRODUCT
      : Types.NewProductType.COMPARABLE_PRODUCT,
  };
}

function getAdoptionChartWidget(
  adoptionMetrics: readonly Types.MetricInstance[],
  selectedFilters: readonly Types.AttributeFilter[],
  dateAttributeInstance: Types.AttributeInstance
): ComputableWidget {
  return {
    ...getDefaultWidgetProperties(),
    customName: 'Product Adoption',
    metrics: adoptionMetrics.map(metricInstance => ({
      metricInstance,
      seriesType: Types.WidgetSeriesType.LINE,
    })),
    metricsSplitIndex: adoptionMetrics.length,
    filters: selectedFilters,
    rowGroupings: [dateAttributeInstance],
    type: Types.WidgetType.CHART,
    id: ADOPTION_CHART_WIDGET_ID,
  };
}

export function getNPIWidget(
  currentUser: CurrentUser,
  availableGroupings: AttributesByTypeAndName,
  availableMetrics: MetricsByName,
  selectedFilters: readonly Types.AttributeFilter[],
  npi: Types.NewProductIntroduction,
  planVersion: Types.PlanVersion,
  showComparableMetric = false
): ComputableWidget {
  const [startDate, endDate] = [npi.startDate, npi.endDate];
  const adoptionMetrics: readonly Types.MetricInstance[] = getAdoptionMetrics(
    availableMetrics,
    currentUser,
    startDate,
    endDate,
    npi,
    planVersion,
    showComparableMetric
  );

  const attributeName = getAttributeNameForGranularity(NEW_PRODUCT_CONFIG_GRANULARITY);
  const attribute = assertTruthy(
    availableGroupings.get(Types.AttributeType.DATE)!.get(attributeName)
  );
  const dateAttributeInstance = {attribute, graphContext: null};

  return getAdoptionChartWidget(adoptionMetrics, selectedFilters, dateAttributeInstance);
}

export function getCurrentNpiConfig(
  allNpiConfigs: readonly Types.NewProductIntroductionConfig[],
  path: readonly number[]
): Types.NewProductIntroductionConfig | null {
  if (!allNpiConfigs?.length) {
    return null;
  }
  return findNpiConfigs(allNpiConfigs, path)[0];
}

export function findNpiConfigs(
  configs: readonly Types.NewProductIntroductionConfig[],
  attributeValueIds: readonly number[]
): readonly Types.NewProductIntroductionConfig[] {
  return configs.filter(config =>
    Set(config.newProductIntroduction.attributeValues.map(av => av.id)).equals(
      Set(attributeValueIds)
    )
  );
}

export function findNpiConfig(
  configs: readonly Types.NewProductIntroductionConfig[],
  newProductType: Types.NewProductType,
  attributeValueIds: readonly number[]
): Types.NewProductIntroductionConfig | undefined {
  return findNpiConfigs(configs, attributeValueIds).find(
    config => config.newProductIntroduction.newProductType === newProductType
  );
}

export function getAdoptionPeriod(npi: Types.NewProductIntroduction) {
  return moment(npi.endDate).diff(npi.startDate, 'weeks');
}

export function getInitialComparableValues(
  npiPath: readonly Types.AttributeValue[]
): readonly Types.AttributeValue[] {
  return npiPath.slice(0, npiPath.length - 1);
}

export function createDefaultNpiConfig(
  currentUser: CurrentUser,
  newProductIntroductions: readonly Types.NewProductIntroductionConfig[],
  newProductSettings: Types.NewProductSettings,
  versionDate: string,
  path: readonly Types.AttributeValue[]
): Types.NewProductIntroductionConfig {
  const startDateOfRecentlyCreatedNpi = maxBy(
    newProductIntroductions.filter(npi => npi.newProductIntroduction.versionDate === versionDate),
    npi => npi.newProductIntroduction.id
  )?.newProductIntroduction?.startDate;

  const startDateMoment = startDateOfRecentlyCreatedNpi
    ? moment(startDateOfRecentlyCreatedNpi)
    : getVendorEvaluationDate(currentUser.settings.analysisSettings.evaluationDate)
        .clone()
        .startOf('week')
        .add(5, 'weeks');
  return {
    newProductIntroduction: {
      id: null,
      attributeValues: path,
      newProductType: NewProductType.NEW_PRODUCT,
      comparableAttributeValues: getInitialComparableValues(path),
      curve: Types.NewProductCurveEnum.BASS_AVERAGE,
      customCurveValues: null,
      startDate: startDateMoment.format(DATE_FORMAT),
      endDate: startDateMoment
        .clone()
        .add(newProductSettings.adoptionPeriodWeeks, 'weeks')
        .format(DATE_FORMAT),
      scaleFactor: 1,
      versionDate,
      archiveDate: null,
      latestPlanDateUsedForMaterialization: null,
    },
    metricSnapshotValues: {},
  };
}

export function filterDirectImportProductsByPaths(
  tree: Types.AttributeHierarchyTree,
  directImportPaths?: ReadonlyArray<readonly number[]>
): Types.AttributeHierarchyTree {
  if (!directImportPaths) {
    return tree;
  }
  const directImportTree = buildArrayTree(directImportPaths.map(path => ({path, value: true})));
  return filterDirectImportsByArrayTree(tree, directImportTree);
}

export function filterDirectImportsByArrayTree(
  tree: Types.AttributeHierarchyTree,
  directImportTree: ArrayTree<number, boolean>
): Types.AttributeHierarchyTree {
  const primaryNodeIdAndTree = Array.from(directImportTree.children.entries()).find(([_, child]) =>
    child.values.includes(true)
  );
  if (primaryNodeIdAndTree !== undefined) {
    const [primaryNodeId, _] = primaryNodeIdAndTree;
    return {
      value: tree.value,
      children: tree.children.filter(child => child.value!.id === primaryNodeId),
    };
  }
  return {
    value: tree.value,
    children: tree.children.map(child => {
      const directImportTreeChild = directImportTree.children.get(assertTruthy(child.value!.id));
      if (!directImportTreeChild) {
        return child;
      }
      return filterDirectImportsByArrayTree(child, directImportTreeChild);
    }),
  };
}

export function getPendingNpiStatus(
  id: number | null | undefined,
  npiPendingStatuses: PendingNpiStatusById
): PendingNpiStatus {
  return npiPendingStatuses.get(id, PendingNpiStatus.UNCHANGED);
}
