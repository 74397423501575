import {Set} from 'immutable';
import React from 'react';
import {connect} from 'react-redux';

import {Alert, clearAlert} from 'redux/actions/alerts';
import {Dispatch, RootState} from 'redux/reducers';
import AlertItem from 'toolkit/components/AlertItem';
import FadeToBottomTransition from 'toolkit/components/FadeToBottomTransition';

class GlobalAlertOverlay extends React.PureComponent<Props, State> {
  private clearTimerId: number | null;

  constructor(props: Props) {
    super(props);
    this.state = {
      aboutToClear: Set(),
    };
    this.clearTimerId = null;
  }

  componentWillUnmount() {
    if (this.clearTimerId) {
      window.clearTimeout(this.clearTimerId);
      this.clearTimerId = null;
    }
  }

  dismissAlertMessage = (alert: Alert) => {
    this.setState({aboutToClear: this.state.aboutToClear.add(alert)}, () =>
      this.clearAfterTimeout(alert)
    );
  };

  clearAfterTimeout = (alert: Alert) => {
    this.clearTimerId = window.setTimeout(() => {
      this.setState({aboutToClear: this.state.aboutToClear.remove(alert)}, () =>
        this.clearAlert(alert)
      );
    }, 500);
  };

  clearAlert = (alert: Alert) => {
    if (alert.customCloseAction) {
      alert.customCloseAction();
    }
    this.props.clearAlert(alert.id);
  };

  render() {
    return this.props.alerts.map((alert, index) => (
      <FadeToBottomTransition
        key={`${index}-${alert.message}`}
        visible={!this.state.aboutToClear.contains(alert)}
      >
        <AlertItem alert={alert} onClose={this.dismissAlertMessage} />
      </FadeToBottomTransition>
    ));
  }
}

interface Props {
  alerts: readonly Alert[];
  clearAlert: (alertId: string) => any;
}
interface State {
  // this keeps alerts visible while they're being cleared, so animations run
  aboutToClear: Set<Alert>;
}

function mapStateToProps(state: RootState) {
  return {
    alerts: state.alerts.alerts,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    clearAlert: (alertId: string) => dispatch(clearAlert(alertId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GlobalAlertOverlay);
