import './AlertItem.scss';

import classNames from 'classnames';
import React from 'react';

import {Alert} from 'redux/actions/alerts';
import Icon from 'toolkit/components/Icon';
import {IconSpec} from 'toolkit/icons/types';
import {getApiErrorStacktrace, reloadApp} from 'utils/exceptions';

import SupportLink from './SupportLink';

const getIcon = (alert: Alert): IconSpec => alert.icon || 'check';

export default class AlertItem extends React.PureComponent<AlertProps> {
  private closeTimerId: number;

  constructor(props: AlertProps) {
    super(props);
    this.closeTimerId = 0;
  }

  componentDidMount() {
    if (this.props.alert.autoCloseIntervalMsec) {
      this.closeTimerId = window.setTimeout(
        this.handleClose,
        this.props.alert.autoCloseIntervalMsec
      );
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.closeTimerId);
  }

  handleClose = () => {
    clearTimeout(this.closeTimerId);
    this.props.onClose(this.props.alert);
  };

  renderMessage = () => {
    if (!this.props.alert.message) {
      return null;
    }

    const parts = this.props.alert.message.split('\n');

    return (
      <>
        {parts.map((part, index) => (
          <React.Fragment key={index}>
            {part}
            <br />
          </React.Fragment>
        ))}
      </>
    );
  };

  render() {
    const stacktrace = getApiErrorStacktrace(this.props.alert.exception);
    return (
      <div
        key="alert-container"
        className={classNames('AlertItem', this.props.className, {
          'with-stacktrace': !!stacktrace,
        })}
      >
        <div className="alert-main">
          <Icon icon={getIcon(this.props.alert)} />
          <div className="alert-content">
            {this.props.alert.contentRenderer
              ? this.props.alert.contentRenderer()
              : this.renderMessage()}
          </div>
          {this.props.alert.showReloadLink && (
            <div className="action-link">
              <a onClick={reloadApp}>Reload</a>
            </div>
          )}
          {!!this.props.alert.exception && !this.props.alert.showReloadLink && (
            <div className="action-link">
              <SupportLink>Contact Support</SupportLink>
            </div>
          )}
          <div className="close-button" onClick={this.handleClose}>
            <Icon icon="times" />
          </div>
        </div>
        {!!stacktrace && <div className="alert-stacktrace">{stacktrace}</div>}
      </div>
    );
  }
}

export interface AlertProps {
  alert: Alert;
  className?: string;
  onClose: (alert: Alert) => void;
}
