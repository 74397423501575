import './SelectOption.scss';

import classNames from 'classnames';
import React from 'react';

import {focusAdjacentItemWithClassName, swallowEvents} from 'toolkit/utils/events';

import Icon from './Icon';

export const SelectOptionValue = ({
  label,
  subtitle,
}: {
  label: React.ReactNode;
  subtitle?: React.ReactNode;
}) => (
  <div className={classNames('SelectOptionValue', {'with-subtitle': !!subtitle})}>
    <div className="value">{label}</div>
    {subtitle && <div className="subtitle">{subtitle}</div>}
  </div>
);

export default class SelectOption<T> extends React.PureComponent<Props<T>> {
  static defaultProps = {
    blurOnArrowLeft: true,
    showCheckOnSelection: false,
    disabled: false,
  };

  handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    swallowEvents(event);
    if (this.props.disabled) {
      return;
    }
    this.props.onSelect(this.props.option, event);
  };

  handleKeys = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'ArrowDown' || event.key === 'ArrowUp' || event.key === 'Tab') {
      const didFocus = focusAdjacentItemWithClassName(event, 'SelectOption');
      if (!didFocus && !event.currentTarget.previousElementSibling) {
        this.props.onBlur(event);
      }
    } else if (event.key === 'ArrowLeft' && this.props.blurOnArrowLeft) {
      swallowEvents(event);
      this.props.onBlur(event);
    } else if (event.key === 'Enter' || event.key === ' ') {
      swallowEvents(event);
      if (this.props.disabled) {
        return;
      }
      this.props.onSelect(this.props.option, event);
    } else if (event.key === 'Escape' || (event.key.length === 1 && /[\w]/.test(event.key))) {
      event.nativeEvent.stopImmediatePropagation();
      this.props.onBlur(event);
    }
  };

  render() {
    return (
      <div
        className={classNames(
          'SelectOption',
          'select-option',
          this.props.showCheckOnSelection && 'show-check',
          this.props.className,
          this.props.disabled && 'disabled'
        )}
        role="button"
        tabIndex={0}
        onClick={this.handleClick}
        onKeyDown={this.handleKeys}
      >
        {this.props.showCheckOnSelection ? (
          <div className="check-container">
            {this.props.selected && <Icon className="check" icon="check" />}
          </div>
        ) : null}
        <SelectOptionValue label={this.props.optionLabel} subtitle={this.props.subtitle} />
        {this.props.descriptionTooltip}
      </div>
    );
  }
}
interface Props<T> {
  className?: string;
  descriptionTooltip?: React.ReactNode;
  optionLabel: React.ReactNode;
  option: T;
  onBlur: (event: React.KeyboardEvent<HTMLElement>) => void;
  onSelect: (option: T, event: React.UIEvent<HTMLElement>) => void;
  subtitle?: React.ReactNode;
  blurOnArrowLeft?: boolean;
  showCheckOnSelection?: boolean;
  selected?: boolean;
  disabled?: boolean;
}
