import {push} from 'connected-react-router';

import * as Api from 'api';
import {VendorLocationChangeAction} from 'app/router-types';
import ActionType from 'redux/actions/types';
import {Dispatch, GetState} from 'redux/reducers';
import {PlanViewFilterType} from 'redux/reducers/plans';
import {PromiseAction} from 'redux/store/middleware/promise';
import {PendingNpiStatus} from 'toolkit/plans/new-product-introduction/utils';
import {pathIdsToForecastSearchString, pathToSearchString} from 'toolkit/plans/utils';
import {getPlanForecastsLink, getPlanLink} from 'toolkit/views/view-urls';
import * as Types from 'types';
import {invalidate} from 'utils/api';
import {PromiseStatus} from 'utils/status';

export type SetPlanSettingsAction = {
  type: ActionType.SetPlanSettings;
} & ({data: Types.PlanSettings} | PromiseAction<Types.PlanSettings>);

export type PlansAction =
  | ({
      type: ActionType.SetPlans;
    } & PromiseAction<readonly Types.PlanVersion[]>)
  | {type: ActionType.RestorePlan; planType: Types.PlanType}
  | {type: ActionType.ClearPlanVersion; planType: Types.PlanType}
  | {type: ActionType.RestoreUnsavedPlanVersion; planType: Types.PlanType}
  | {
      type: ActionType.UpdatePlanVersion;
      oldPlan: Types.PlanVersion;
      newPlan: Types.PlanVersion;
    }
  | {
      type: ActionType.SetLastSavedPlanVersion;
      planVersion: Types.PlanVersion;
      planType: Types.PlanType;
    }
  | {type: ActionType.SetActivePlanDiff; planDiff: Types.PlanDiff | null; planType: Types.PlanType}
  | {type: ActionType.SetPendingPlanDiff; planDiff: Types.PlanDiff | null; planType: Types.PlanType}
  | {type: ActionType.SetDeletedManualAdjustmentsSinceLastUpdate}
  | {type: ActionType.ClearDeletedManualAdjustmentsSinceLastUpdate}
  | SetPlanSettingsAction
  | {
      type: ActionType.SetSelectedPlanningPath;
      selectedPath: readonly Types.AttributeFilter[];
    }
  | {type: ActionType.SetPlanViewFilterType; value: PlanViewFilterType}
  | ({
      type: ActionType.FetchNewProductIntroductions;
    } & PromiseAction<readonly Types.NewProductIntroductionConfig[]>)
  | {
      type: ActionType.UpdateNewProductIntroduction;
      id?: number | null;
      npiConfig: Types.NewProductIntroductionConfig;
    }
  | {
      type: ActionType.UpdateNewProductIntroductions;
      npiConfigs: readonly Types.NewProductIntroductionConfig[];
    }
  | {
      type: ActionType.SetPendingNpiStatus;
      id: number;
      pendingNpiStatus: PendingNpiStatus;
    }
  | {type: ActionType.ClearPendingNpiStatuses}
  | ({
      type: ActionType.FetchDisabledForecastTypes;
    } & PromiseAction<readonly Types.ForecastType[]>)
  | ({
      type: ActionType.FetchPlanDemandSources;
    } & PromiseAction<readonly Types.PlanDemandSourceMapping[]>)
  | ({
      type: ActionType.FetchDiscontinuedSkus;
    } & PromiseAction<readonly Types.DiscontinuedSku[]>)
  | {
      type: ActionType.SetForecastPageSelectionPath;
      path: readonly number[];
    }
  | {type: ActionType.ClearForecastsPageUnsavedChanges}
  | VendorLocationChangeAction
  | {type: ActionType.RefreshCalendarEventWidgets}
  | {
      type: ActionType.SetIsViewingDirectImportSecondaryProduct;
      isViewingDirectImportSecondaryProduct: boolean;
    }
  | {type: ActionType.SetRefreshNeeded; refreshNeeded: boolean};

export function goToPlanType(
  type: Types.PlanType,
  planFilterSelection?: readonly Types.ThinAttributeValue[]
) {
  return (dispatch: Dispatch, getState: GetState) =>
    new Promise<void>(resolve => {
      dispatch(
        push(
          getPlanLink(getState().user.vendor!.name, type, pathToSearchString(planFilterSelection))
        )
      );
      resolve();
    });
}

export function goToForecasts(pathIds?: readonly number[]) {
  return (dispatch: Dispatch, getState: GetState) =>
    new Promise<void>(resolve => {
      dispatch(
        push(
          getPlanForecastsLink(getState().user.vendor!.name, pathIdsToForecastSearchString(pathIds))
        )
      );
      dispatch(setForecastPageSelectionPath(pathIds ?? []));
      resolve();
    });
}

export function fetchPlans(): PlansAction {
  return {
    promise: Api.Plans.getActivePlans(),
    type: ActionType.SetPlans,
    state: PromiseStatus.request,
  };
}

export function restorePlan(planType: Types.PlanType): PlansAction {
  return {
    type: ActionType.RestorePlan,
    planType,
  };
}

export function updatePlanVersion(
  oldPlanVersion: Types.PlanVersion,
  newPlanVersion: Types.PlanVersion
): PlansAction {
  return {
    type: ActionType.UpdatePlanVersion,
    oldPlan: oldPlanVersion,
    newPlan: newPlanVersion,
  };
}

export function clearPlanVersion(planType: Types.PlanType): PlansAction {
  return {
    type: ActionType.ClearPlanVersion,
    planType,
  };
}

export function restoreUnsavedPlanVersion(planType: Types.PlanType): PlansAction {
  return {
    type: ActionType.RestoreUnsavedPlanVersion,
    planType,
  };
}

export function setLastSavedPlanVersion(planVersion: Types.PlanVersion): PlansAction {
  return {
    type: ActionType.SetLastSavedPlanVersion,
    planVersion,
    planType: planVersion.basePlan.type,
  };
}

export function setActivePlanDiff(
  planDiff: Types.PlanDiff | null,
  planType: Types.PlanType
): PlansAction {
  return {
    type: ActionType.SetActivePlanDiff,
    planDiff,
    planType,
  };
}

export function setPendingPlanDiff(
  planDiff: Types.PlanDiff | null,
  planType: Types.PlanType
): PlansAction {
  return {
    type: ActionType.SetPendingPlanDiff,
    planDiff,
    planType,
  };
}

export function setDeletedManualAdjustmentsSinceLastUpdate(): PlansAction {
  return {
    type: ActionType.SetDeletedManualAdjustmentsSinceLastUpdate,
  };
}

export function clearDeletedManualAdjustmentsSinceLastUpdate(): PlansAction {
  return {
    type: ActionType.ClearDeletedManualAdjustmentsSinceLastUpdate,
  };
}

export function setPlanSettings(planSettings: Types.PlanSettings): PlansAction {
  return {
    type: ActionType.SetPlanSettings,
    data: planSettings,
  };
}

export function fetchPlanSettings(): PlansAction {
  return {
    type: ActionType.SetPlanSettings,
    promise: Api.Plans.getPlanSettings(),
    state: PromiseStatus.request,
  };
}

export function setPlanViewFilterType(value: PlanViewFilterType): PlansAction {
  return {
    type: ActionType.SetPlanViewFilterType,
    value,
  };
}

export function fetchNewProductIntroductions(): PlansAction {
  return {
    type: ActionType.FetchNewProductIntroductions,
    promise: Api.NewProductIntroductions.getNewProductIntroductions(),
    state: PromiseStatus.request,
  };
}

export function updateNewProductIntroduction(
  id: number | null,
  npiConfig: Types.NewProductIntroductionConfig
): PlansAction {
  return {
    type: ActionType.UpdateNewProductIntroduction,
    id,
    npiConfig,
  };
}

export function updateNewProductIntroductions(
  npiConfigs: readonly Types.NewProductIntroductionConfig[]
): PlansAction {
  return {
    type: ActionType.UpdateNewProductIntroductions,
    npiConfigs,
  };
}

export function setPendingNpiStatus(id: number, pendingNpiStatus: PendingNpiStatus): PlansAction {
  return {
    type: ActionType.SetPendingNpiStatus,
    id,
    pendingNpiStatus,
  };
}

export function clearPendingNpiStatuses(): PlansAction {
  return {
    type: ActionType.ClearPendingNpiStatuses,
  };
}

export function fetchDisabledForecastTypes(): PlansAction {
  return {
    type: ActionType.FetchDisabledForecastTypes,
    promise: Api.Plans.getPlanDisabledForecastTypes(),
    state: PromiseStatus.request,
  };
}

export function setForecastPageSelectionPath(path: readonly number[]): PlansAction {
  return {
    type: ActionType.SetForecastPageSelectionPath,
    path,
  };
}

export function clearForecastsPageUnsavedChanges(): PlansAction {
  return {
    type: ActionType.ClearForecastsPageUnsavedChanges,
  };
}

export function setSelectedPlanningPath(
  selectedPath: readonly Types.AttributeFilter[]
): PlansAction {
  return {
    type: ActionType.SetSelectedPlanningPath,
    selectedPath,
  };
}

export function fetchPlanDemandSourceMap(): PlansAction {
  return {
    type: ActionType.FetchPlanDemandSources,
    promise: Api.Plans.getPlanDemandSources(),
    state: PromiseStatus.request,
  };
}

export function fetchDiscontinueSkusByPath(): PlansAction {
  return {
    type: ActionType.FetchDiscontinuedSkus,
    promise: Api.Plans.getDiscontinuedSkus(),
    state: PromiseStatus.request,
  };
}

export function setIsViewingDirectImportSecondaryProduct(
  isViewingDirectImportSecondaryProduct: boolean
): PlansAction {
  return {
    type: ActionType.SetIsViewingDirectImportSecondaryProduct,
    isViewingDirectImportSecondaryProduct,
  };
}

export function setRefreshNeededFor(planId: number, alsoRefreshSidebar: boolean): PlansAction {
  if (alsoRefreshSidebar) {
    invalidate(Api.Plans.getActivePlanningTree.getResource(planId));
  }
  return {
    type: ActionType.SetRefreshNeeded,
    refreshNeeded: true,
  };
}

export function clearRefreshNeeded(): PlansAction {
  return {
    type: ActionType.SetRefreshNeeded,
    refreshNeeded: false,
  };
}
