// This needs to be in sync with Notification.java's Key inner class
export enum NotificationKey {
  PARTNER_ID = 'PARTNER_ID',
  FILE_ID = 'FILE_ID',
  VDSF_ID = 'VDSF_ID',
  DATA_ISSUE_TYPE = 'DATA_ISSUE_TYPE',
}

// This needs to be in sync with DataIssueNotificationType.java
export enum DataIssueNotificationType {
  FORWARD_FILE_ISSUE = 'FORWARD_FILE_ISSUE',
  MISSING_FILE = 'MISSING_FILE',
}
