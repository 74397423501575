import {useDebugValue, useEffect, useMemo} from 'react';

import useForceUpdate from 'toolkit/utils/useForceUpdate';
import {NetworkRequestState} from 'utils/ajax';
import {Resource, RESOURCE_NOT_SET} from 'utils/api';
import {Status} from 'utils/status';

export type ApiSelector<S> = S & {
  private__isApiSelector: true;
};

/**
 * Marks a function as an API selector. In combination with a lint rule enforcing this be called statically, this ensures
 * that any functions passed to `useApi(...).withTransform` have a static identity that we can use as a cache key.
 */
export const createApiSelector = <S>(selector: S): ApiSelector<S> => {
  const result = selector as ApiSelector<S>;
  result.private__isApiSelector = true as const;
  return result;
};

export interface SubscribedResource<T> {
  privateDoNotUse(): T;
}

export function prefetch(resource: Resource<unknown>): void {
  if (!resource.isComplete()) {
    resource.privateDoNotUse_fetchIfNeeded();
  }
}

// Note: we require the underlying result to be accessed by useResult to reflect the fact that
// this can only be safely called in contexts where it is also ok to use a hook, as it may
// throw a promise that needs to be caught by suspense.
export function useResult<T>(resource: SubscribedResource<T>) {
  return resource.privateDoNotUse();
}

function useResourceSubscription(resource: Resource<unknown>) {
  const forceUpdate = useForceUpdate();
  useEffect(() => {
    resource.subscribe(forceUpdate);
    return () => resource.unsubscribe(forceUpdate);
  }, [resource, forceUpdate]);
}

/**
 * See https://app.getguru.com/card/c9RRrxxi for the rationale behind this hook and its usage guide.
 */
export function useApi<T>(resource: Resource<T>) {
  useResourceSubscription(resource);
  useDebugValue(resource.currentValue);

  if (!resource.isComplete()) {
    resource.privateDoNotUse_fetchIfNeeded();
  }

  return useMemo(
    () => ({
      withTransform<S extends (value: T) => any>(
        selector: ApiSelector<S>
      ): SubscribedResource<ReturnType<S>> {
        return {
          privateDoNotUse() {
            if (resource.currentValue !== RESOURCE_NOT_SET) {
              if (resource.private__cachedTransforms.has(selector)) {
                return resource.private__cachedTransforms.get(selector) as ReturnType<S>;
              } else {
                const value = selector(resource.currentValue);
                resource.private__cachedTransforms.set(selector, value);
                return value;
              }
            } else if (resource.currentError !== RESOURCE_NOT_SET) {
              throw resource.currentError;
            } else {
              throw resource.privateDoNotUse_fetchIfNeeded();
            }
          },
        };
      },

      privateDoNotUse() {
        if (resource.currentValue !== RESOURCE_NOT_SET) {
          return resource.currentValue;
        } else if (resource.currentError !== RESOURCE_NOT_SET) {
          throw resource.currentError;
        } else {
          // Throw a promise to trigger React Suspense, which catches the promise
          // and suspends rendering of the calling component and any other components
          // under a parent `<React.Suspense>` component.
          // This is an experimental React feature. For details, see:
          // https://reactjs.org/docs/concurrent-mode-suspense.html
          throw resource.privateDoNotUse_fetchIfNeeded();
        }
      },
    }),
    [resource]
  );
}

export function useNonBlockingApi<T>(resource: Resource<T>): NetworkRequestState<T> {
  useResourceSubscription(resource);

  if (!resource.isComplete()) {
    resource.privateDoNotUse_fetchIfNeeded();
  }

  return useMemo(() => {
    if (resource.currentValue !== RESOURCE_NOT_SET) {
      return {status: Status.succeeded, result: resource.currentValue};
    } else if (resource.currentError !== RESOURCE_NOT_SET) {
      return {status: Status.failed, result: resource.currentError};
    } else {
      return {status: Status.inProgress, result: null};
    }
  }, [resource.currentError, resource.currentValue]);
}

export function useStatus(resource: Resource<unknown>): Status {
  useResourceSubscription(resource);

  // Note: we prioritize in-progress over succeeded in the event of optimistic invalidation to enable displaying the
  // status of this sort of invalidation
  if (resource.currentRequest !== RESOURCE_NOT_SET) {
    return Status.inProgress;
  } else if (resource.currentValue !== RESOURCE_NOT_SET) {
    return Status.succeeded;
  } else if (resource.currentError !== RESOURCE_NOT_SET) {
    return Status.failed;
  } else {
    return Status.unstarted;
  }
}
