/* eslint-disable */

/*
  This file is generated from _base_colors.scss and _color_tokens.scss specification.
  To update, run `ore amalgam codegen`.
*/

const enum BaseColors {
  AlloyGreen10 = '#f0fbfa',
  AlloyGreen15 = '#dff7f4',
  AlloyGreen20 = '#cff2ef',
  AlloyGreen30 = '#aeeae4',
  AlloyGreen40 = '#8ee1d9',
  AlloyGreen50 = '#4ccfc3',
  AlloyGreen60 = '#00bbaa',
  AlloyGreen70 = '#00a293',
  AlloyGreen80 = '#00897c',
  AlloyGreen90 = '#006f65',
  AlloyGreen100 = '#00564e',
  Grey05 = '#f9f9fa',
  Grey10 = '#f3f4f5',
  Grey15 = '#e8eaeb',
  Grey20 = '#dcdfe1',
  Grey30 = '#bfc4c9',
  Grey40 = '#a0a7af',
  Grey50 = '#848d97',
  Grey60 = '#6a7481',
  Grey70 = '#535f6d',
  Grey80 = '#3e4c5c',
  Grey90 = '#2d3b4a',
  Grey100 = '#192029',
  WarmGrey05 = '#f4f4f4',
  WarmGrey10 = '#dcdcdc',
  WarmGrey20 = '#bfbfbf',
  WarmGrey30 = '#a2a2a2',
  WarmGrey40 = '#868686',
  WarmGrey50 = '#6e6e6e',
  WarmGrey60 = '#595959',
  WarmGrey70 = '#444444',
  WarmGrey80 = '#333333',
  WarmGrey90 = '#1f1f1f',
  Red10 = '#fdf5f4',
  Red15 = '#fbeae8',
  Red20 = '#f9dfdc',
  Red30 = '#f4c9c4',
  Red40 = '#f0b4ac',
  Red50 = '#e7887c',
  Red60 = '#dd5544',
  Red70 = '#bf4a3b',
  Red80 = '#a13e32',
  Red90 = '#833328',
  Red100 = '#66271f',
  Orange10 = '#fefaf4',
  Orange20 = '#fcefdc',
  Orange30 = '#fae4c4',
  Orange40 = '#f7d9ac',
  Orange50 = '#f3c37c',
  Orange60 = '#eeaa44',
  Orange70 = '#ce933b',
  Orange80 = '#ae7c32',
  Orange90 = '#8e6528',
  Orange100 = '#6d4e1f',
  Yellow10 = '#fffdf0',
  Yellow20 = '#fff9cf',
  Yellow30 = '#fff4ae',
  Yellow40 = '#fff08e',
  Yellow50 = '#ffe74c',
  Yellow60 = '#ffdd00',
  Yellow70 = '#ddbf00',
  Yellow80 = '#baa100',
  Yellow90 = '#988300',
  Yellow100 = '#756600',
  Green10 = '#f5fbf5',
  Green20 = '#dff2df',
  Green30 = '#c9eac9',
  Green40 = '#b4e1b4',
  Green50 = '#88cf88',
  Green60 = '#55bb55',
  Green70 = '#4aa24a',
  Green80 = '#3e893e',
  Green90 = '#336f33',
  Green100 = '#275627',
  Cyan10 = '#f0fafd',
  Cyan20 = '#d1f0fa',
  Cyan30 = '#b2e6f6',
  Cyan40 = '#93dcf3',
  Cyan50 = '#55c8ec',
  Cyan60 = '#0cb1e4',
  Cyan70 = '#0a99c5',
  Cyan80 = '#0981a6',
  Cyan90 = '#076988',
  Cyan100 = '#065169',
  Blue10 = '#f0f6fb',
  Blue20 = '#cfe4f2',
  Blue30 = '#aed1ea',
  Blue40 = '#8ebfe1',
  Blue50 = '#4c99cf',
  Blue60 = '#006ebb',
  Blue70 = '#005fa2',
  Blue80 = '#005089',
  Blue90 = '#00416f',
  Blue100 = '#003356',
  Indigo10 = '#f2f4fa',
  Indigo20 = '#d6dcef',
  Indigo30 = '#bac5e4',
  Indigo40 = '#9fadda',
  Indigo50 = '#677ec4',
  Indigo60 = '#2647ab',
  Indigo70 = '#213d94',
  Indigo80 = '#1c347d',
  Indigo90 = '#172a66',
  Indigo100 = '#11214f',
  Purple10 = '#f8f4fa',
  Purple20 = '#eadcf0',
  Purple30 = '#dbc4e5',
  Purple40 = '#cdacdb',
  Purple50 = '#b07cc6',
  Purple60 = '#8e44ad',
  Purple70 = '#7b3b96',
  Purple80 = '#68327e',
  Purple90 = '#542867',
  Purple100 = '#411f50',
}

export const enum AlloyColors {
  Brand = BaseColors.AlloyGreen60,
  PrimaryLightest = BaseColors.AlloyGreen10,
  PrimaryLighter = BaseColors.AlloyGreen20,
  PrimaryLight = BaseColors.AlloyGreen30,
  Primary = BaseColors.AlloyGreen70,
  PrimaryDark = BaseColors.AlloyGreen80,
  PrimaryDarker = BaseColors.AlloyGreen90,
  PrimaryDarkest = BaseColors.AlloyGreen100,
  AlloyGreen15 = BaseColors.AlloyGreen15,
  AlloyGreen40 = BaseColors.AlloyGreen40,
  GreyLightest = BaseColors.Grey10,
  GreyLighter = BaseColors.Grey20,
  GreyLight = BaseColors.Grey30,
  Grey = BaseColors.Grey40,
  GreyDark = BaseColors.Grey60,
  GreyDarker = BaseColors.Grey90,
  GreyDarkest = BaseColors.Grey100,
  Grey05 = BaseColors.Grey05,
  Grey15 = BaseColors.Grey15,
  Grey50 = BaseColors.Grey50,
  Grey70 = BaseColors.Grey70,
  WarmGrey05 = BaseColors.WarmGrey05,
  WarmGrey10 = BaseColors.WarmGrey10,
  WarmGrey20 = BaseColors.WarmGrey20,
  WarmGrey30 = BaseColors.WarmGrey30,
  WarmGrey50 = BaseColors.WarmGrey50,
  WarmGrey60 = BaseColors.WarmGrey60,
  Orange40 = BaseColors.Orange40,
  RedLightest = BaseColors.Red10,
  RedLighter = BaseColors.Red20,
  RedLight = BaseColors.Red30,
  Red = BaseColors.Red60,
  RedDark = BaseColors.Red70,
  RedDarker = BaseColors.Red80,
  RedDarkest = BaseColors.Red90,
  OrangeLightest = BaseColors.Orange10,
  OrangeLighter = BaseColors.Orange20,
  OrangeLight = BaseColors.Orange30,
  Orange = BaseColors.Orange60,
  OrangeDark = BaseColors.Orange70,
  OrangeDarker = BaseColors.Orange80,
  OrangeDarkest = BaseColors.Orange90,
  YellowLightest = BaseColors.Yellow10,
  YellowLighter = BaseColors.Yellow20,
  YellowLight = BaseColors.Yellow30,
  Yellow = BaseColors.Yellow70,
  YellowDark = BaseColors.Yellow80,
  YellowDarker = BaseColors.Yellow90,
  YellowDarkest = BaseColors.Yellow100,
  GreenLightest = BaseColors.Green10,
  GreenLighter = BaseColors.Green20,
  GreenLight = BaseColors.Green40,
  Green = BaseColors.Green60,
  GreenDark = BaseColors.Green70,
  GreenDarker = BaseColors.Green80,
  GreenDarkest = BaseColors.Green100,
  CyanLightest = BaseColors.Cyan10,
  CyanLighter = BaseColors.Cyan20,
  CyanLight = BaseColors.Cyan30,
  Cyan = BaseColors.Cyan40,
  CyanDark = BaseColors.Cyan60,
  CyanDarker = BaseColors.Cyan70,
  CyanDarkest = BaseColors.Cyan80,
  BlueLightest = BaseColors.Blue10,
  BlueLighter = BaseColors.Blue20,
  BlueLight = BaseColors.Blue30,
  Blue = BaseColors.Blue40,
  BlueDark = BaseColors.Blue60,
  BlueDarker = BaseColors.Blue70,
  BlueDarkest = BaseColors.Blue80,
  IndigoLightest = BaseColors.Indigo10,
  IndigoLighter = BaseColors.Indigo20,
  IndigoLight = BaseColors.Indigo30,
  Indigo = BaseColors.Indigo50,
  IndigoDark = BaseColors.Indigo60,
  IndigoDarker = BaseColors.Indigo70,
  IndigoDarkest = BaseColors.Indigo90,
  PurpleLightest = BaseColors.Purple10,
  PurpleLighter = BaseColors.Purple20,
  PurpleLight = BaseColors.Purple30,
  Purple = BaseColors.Purple50,
  PurpleDark = BaseColors.Purple60,
  PurpleDarker = BaseColors.Purple70,
  PurpleDarkest = BaseColors.Purple90,
}

export const chartColors = [
  '#2aa297',
  '#29729d',
  '#e7af5a',
  '#de887d',
  '#5b9f5b',
  '#cebb40',
  '#7182b7',
  '#33a4c7',
  '#a982ba',
];
