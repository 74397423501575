import './CopyableText.scss';

import classNames from 'classnames';
import React, {useRef} from 'react';

import {showAlert} from 'app/alerts';
import usePrevious from 'toolkit/utils/usePrevious';

import {ButtonDisplayVariant} from './Button';
import IconButton from './IconButton';
import Input, {InputProps} from './Input';

const CopyableText: React.FC<Props> = ({
  borderless,
  condensed = true,
  iconPlacement = 'left',
  highlightChanges = false,
  monospace = true,
  showIcon = true,
  asInput = true,
  readOnly,
  value,
  ...rest
}) => {
  const inputElement = useRef<HTMLInputElement | null>(null);

  const copy = () => {
    navigator.clipboard.writeText(String(value));
    setShouldHighlight(false);
    showAlert('Copied to clipboard.');
  };

  const [shouldHighlight, setShouldHighlight] = React.useState(false);
  const previousValue = usePrevious(value);

  if (highlightChanges && previousValue !== value && !!previousValue && !shouldHighlight) {
    setShouldHighlight(true);
  }

  const icon = showIcon && (
    <IconButton
      className={classNames('copy-icon', {condensed})}
      displayVariant={ButtonDisplayVariant.TERTIARY}
      icon={['far', 'clipboard']}
      onClick={copy}
    />
  );

  const className = classNames('text', {
    borderless,
    condensed,
    monospace,
    highlighted: shouldHighlight,
  });

  return (
    <div className={classNames('CopyableText', {'non-input': !asInput})} onClick={copy}>
      {iconPlacement === 'left' && icon}
      {asInput ? (
        <Input
          ref={inputElement}
          className={className}
          type="text"
          value={value}
          readOnly
          {...rest}
        />
      ) : (
        <span className={className}>{value}</span>
      )}
      {iconPlacement === 'right' && icon}
    </div>
  );
};
CopyableText.displayName = 'CopyableText';

type Props = InputProps & {
  asInput?: boolean;
  borderless?: boolean;
  condensed?: boolean;
  iconPlacement?: 'left' | 'right';
  highlightChanges?: boolean;
  monospace?: boolean;
  showIcon?: boolean;
};

export default CopyableText;
