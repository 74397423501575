import chroma from 'chroma-js';

import {AlloyColors} from 'colors';
import * as Types from 'types';

// Factor greater than 1.0 makes the colors relatively brighter; < 1 makes it darker.
export const adjustLuminanceByFactor = (color: string, luminanceFactor = 1) => {
  const colorLuminance = chroma(color).luminance();
  return chroma(color)
    .luminance(colorLuminance * luminanceFactor)
    .hex();
};

export const setOpacity = (color: string, opacity: number) => {
  return `${color.slice(0, 7)}${percentToHex(opacity)}`;
};

// Modified from: https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4?permalink_comment_id=4503706#gistcomment-4503706
/**
 * Takes a percentage p between 0 and 1 and returns the hex code for that opacity
 */
function percentToHex(p: number) {
  return `0${Math.round(255 * p).toString(16)}`.slice(-2).toUpperCase();
}

export const White = '#ffffff';
export const Black = '#000000';

export const LIGHT_COLORS: Map<Types.Color, string> = new Map([
  [Types.Color.RED, AlloyColors.RedLight],
  [Types.Color.ORANGE, AlloyColors.OrangeLight],
  [Types.Color.YELLOW, AlloyColors.YellowLight],
  [Types.Color.GREEN, AlloyColors.GreenLight],
  [Types.Color.CYAN, AlloyColors.CyanLight],
  [Types.Color.BLUE, AlloyColors.BlueLight],
  [Types.Color.INDIGO, AlloyColors.IndigoLight],
  [Types.Color.PURPLE, AlloyColors.PurpleLight],
]);

export const LIGHTEST_COLORS = new Map([
  [Types.Color.RED, AlloyColors.RedLightest],
  [Types.Color.ORANGE, AlloyColors.OrangeLightest],
  [Types.Color.YELLOW, AlloyColors.YellowLightest],
  [Types.Color.GREEN, AlloyColors.GreenLightest],
  [Types.Color.CYAN, AlloyColors.CyanLightest],
  [Types.Color.BLUE, AlloyColors.BlueLightest],
  [Types.Color.INDIGO, AlloyColors.IndigoLightest],
  [Types.Color.PURPLE, AlloyColors.PurpleLightest],
]);

export const DARK_COLORS: Map<Types.Color, string> = new Map([
  [Types.Color.RED, AlloyColors.RedDark],
  [Types.Color.ORANGE, AlloyColors.OrangeDark],
  [Types.Color.YELLOW, AlloyColors.YellowDark],
  [Types.Color.GREEN, AlloyColors.GreenDark],
  [Types.Color.CYAN, AlloyColors.CyanDark],
  [Types.Color.BLUE, AlloyColors.BlueDark],
  [Types.Color.INDIGO, AlloyColors.IndigoDark],
  [Types.Color.PURPLE, AlloyColors.PurpleDark],
]);

export const TABLE_COLUMN_HOVER_COLORS = LIGHT_COLORS;
export const TABLE_COLUMN_COLORS = LIGHTEST_COLORS;
export const TABLE_COLUMN_TEXT_COLORS = DARK_COLORS;
