import equal from 'fast-deep-equal';
import {Map, Set} from 'immutable';
import moment from 'moment-timezone';
import {v4 as uuid} from 'uuid';

import * as Api from 'api';
import {getForecastMetricArgumentsFromSettings} from 'planning/forecasts/utils';
import {hasDifferentAdjustments, hasDifferentOverrides} from 'planning/utils';
import {CurrentUser, CurrentUserState} from 'redux/reducers/user';
import {Settings} from 'settings/utils';
import {toDefaultAttributeHierarchyCollectionList} from 'toolkit/attributes/hierarchyUtils';
import {ComputeResultExtended} from 'toolkit/compute/types';
import {FeatureFlag} from 'toolkit/feature-flags/types';
import {DATE_FORMAT, DISPLAY_DATE_FORMAT} from 'toolkit/format/constants';
import {IconSpec} from 'toolkit/icons/types';
import {PendingNpiStatus, PendingNpiStatusById} from 'toolkit/plans/new-product-introduction/utils';
import {hasPermission} from 'toolkit/users/utils';
import {CompositeResult, PlanningWidgetData} from 'toolkit/views/types';
import * as Types from 'types';
import {CompareFunction} from 'utils/arrays';
import {assertTruthy} from 'utils/assert';
import {getEndToEndTestPlanningPeriod} from 'utils/cookies';
import {getComputeResult} from 'widgets/widget-data';

import planIcon from './icons/icon-plan.svg';

export const PLANNING_SLUG_PREFIX = 'static-planning-page';

export const PLANNING_TREE_FILTER_BAR_WIDGET_NAME = 'PLANNING_TREE_FILTER_BAR_WIDGET';

export const ALLOWED_PLAN_CALENDAR_UNITS = Set.of(Types.CalendarUnit.WEEKS);
const UPDATED_PLAN_METRIC_DISPLAY_NAMES_BY_METRIC_NAME: {
  [key: string]: string;
} = {
  sales_units_net: 'Point of Sale',
  sales_units_gross: 'Point of Sale',
  forecast_sales_units_net: 'Forecasted Point of Sale',
  forecast_inbound_shipped_units: 'Forecasted Shipments',
};
const PLAN_ICON: IconSpec = {alloyIcon: planIcon};

export const isPlanningView = (view: Types.View) => view.slug === PLANNING_SLUG_PREFIX;

export type PlanVersionsByType = Map<Types.PlanType, Types.PlanVersion>;

// TODO: The name should reflect the cadence, e.g. March 2017 instead of 2017-03-01
export function getPlanDisplayName(planVersion: Types.PlanVersion) {
  return moment(planVersion.basePlan.date).format(DISPLAY_DATE_FORMAT);
}

export function planningDataNeedsRefresh(
  activePlanVersion: Types.PlanVersion | null,
  pendingPlanVersion: Types.PlanVersion | null,
  pendingNpiStatuses: PendingNpiStatusById | null
) {
  if (!activePlanVersion || !pendingPlanVersion) {
    return false;
  }
  const shouldConsiderNpis = activePlanVersion.basePlan.type === Types.PlanType.DEMAND;

  return (
    hasDifferentAdjustments(pendingPlanVersion, activePlanVersion) ||
    hasDifferentOverrides(pendingPlanVersion, activePlanVersion) ||
    (shouldConsiderNpis &&
      !!pendingNpiStatuses &&
      pendingNpiStatuses.filter(status => status !== PendingNpiStatus.UNCHANGED).size > 0)
  );
}

export function canSavePlanVersion(
  planVersions: PlanVersionsByType,
  activePlanVersion: Types.PlanVersion | null,
  pendingPlanVersion: Types.PlanVersion | null,
  pendingNpiStatuses: PendingNpiStatusById
) {
  const savedToActiveChanged =
    activePlanVersion &&
    isPlanVersionDirty(planVersions.get(activePlanVersion.basePlan.type), activePlanVersion);
  const activeToPendingChanged = isPlanVersionDirty(activePlanVersion, pendingPlanVersion);
  const npisChanged = pendingNpiStatuses.size > 0;
  return savedToActiveChanged || activeToPendingChanged || npisChanged;
}

function isPlanningEnabled(currentUser: CurrentUserState) {
  return (
    currentUser.featureFlags.has(FeatureFlag.PLAN_APPLICATION) &&
    !!currentUser.vendor?.isDemandPlanningEnabled &&
    hasPermission(currentUser, Types.PermissionKey.PLANNING_USE_DEMAND_PLANNING)
  );
}

export function isDemandPlanningApplicationEnabled(
  currentUser: CurrentUserState,
  policies?: readonly Types.AttributeFilter[] | null
): boolean {
  // FIXME:  Currently we don't support usage of planning if user has any
  //  data permissions, remove this once the constraints has lifted
  const hasAnyPolicies = policies && policies.length > 0;
  return isPlanningEnabled(currentUser) && !hasAnyPolicies;
}

export function isInventoryPlanningApplicationEnabled(
  currentUser: CurrentUserState,
  policies?: readonly Types.AttributeFilter[] | null
) {
  return (
    isDemandPlanningApplicationEnabled(currentUser, policies) &&
    !!currentUser.vendor?.isInventoryPlanningEnabled &&
    hasPermission(currentUser, Types.PermissionKey.PLANNING_USE_INVENTORY_PLANNING) &&
    currentUser.featureFlags.has(FeatureFlag.INVENTORY_PLANNING)
  );
}

export function getPlanIcon(): IconSpec {
  return PLAN_ICON;
}

const compareAttributeValues: CompareFunction<readonly Types.ThinAttributeValue[]> = (
  attributeValues1,
  attributeValues2
) => {
  if (attributeValues1.length !== attributeValues2.length) {
    return attributeValues1.length - attributeValues2.length;
  }
  // eslint-disable-next-line fp/no-let
  for (let i = 0; i < attributeValues1.length; i++) {
    const id1 = assertTruthy(attributeValues1[i].id);
    const id2 = assertTruthy(attributeValues2[i].id);
    if (id1 !== id2) {
      return id1 - id2;
    }
  }
  return 0;
};

const compareManualAdjustments: CompareFunction<Types.ThinUserManualAdjustment> = (
  adjustment1,
  adjustment2
) => {
  const intervalComparison = adjustment1.metricValue.interval.start.localeCompare(
    adjustment2.metricValue.interval.start
  );
  if (intervalComparison !== 0) {
    return intervalComparison;
  }
  return compareAttributeValues(adjustment1.attributeValues, adjustment2.attributeValues);
};

// Manual adjustments and forecast overrides are stored as sets in pewter, so order is not guaranteed
function getComparablePlan(
  planVersion: Types.PlanVersion | null | undefined
): Types.PlanVersion | null | undefined {
  if (!planVersion) {
    return planVersion;
  }

  return {
    ...planVersion,
    manualAdjustments: [...planVersion.manualAdjustments].sort(compareManualAdjustments),
    forecastOverrides: [...planVersion.forecastOverrides].sort((override1, override2) =>
      compareAttributeValues(override1.attributeValues, override2.attributeValues)
    ),
  };
}

export function getDemandPlanningForecastMetricArguments(
  settings: Settings,
  demandPlanSettings: Types.DemandPlanSettings,
  period: Types.DatePeriod
): Types.MetricArguments {
  const defaultAnalysisArguments = getForecastMetricArgumentsFromSettings(settings, period);

  return {
    ...defaultAnalysisArguments,
    historicalPeriod: demandPlanSettings.historicalPeriod,
    growthFactor: demandPlanSettings.growthFactor,
  };
}

export function isPlanVersionDirty(
  originalPlan: Types.PlanVersion | null | undefined,
  activePlan: Types.PlanVersion | null | undefined
) {
  const comparableOriginalPlan = getComparablePlan(originalPlan);
  const comparableActivePlan = getComparablePlan(activePlan);
  return activePlan && !equal(comparableOriginalPlan, comparableActivePlan);
}

export function getFiltersAsAttributeValues(filters: readonly Types.AttributeFilter[]) {
  return filters.map(filter => filter.values[0]);
}

export function getFiltersAsAttributeValueIds(
  filters: readonly Types.AttributeFilter[]
): readonly number[] {
  return getFiltersAsAttributeValues(filters).map(value => assertTruthy(value.id));
}

export function getPlanCalendar(
  config: Types.Widget,
  planVersion: Types.PlanVersion | undefined
): Types.RetailCalendarEnum | null {
  if (!planVersion) {
    return null;
  }
  return containsGroupings(config, planVersion.basePlan.attributes)
    ? planVersion.basePlan.calendar
    : null;
}

export function containsGroupings(
  widget: Types.Widget,
  groupings: readonly Types.Attribute[]
): boolean {
  // the type doesn't matter here, but we need one to reuse toDefaultAttributeHierarchyCollectionList
  const hierarchyType = Types.AttributeHierarchyType.DEMAND_PLAN;
  return [widget.rowGroupings, widget.columnGroupings].some(widgetGroupings =>
    toDefaultAttributeHierarchyCollectionList(
      widgetGroupings,
      Map.of(hierarchyType, groupings),
      attributeInstance => attributeInstance.attribute?.id
    ).some(haa => haa.attributeHierarchyType === hierarchyType)
  );
}

export function getPlanDefaultPeriod(visibilityPeriod: Types.DatePeriod) {
  const periodFromCookie = getEndToEndTestPlanningPeriod();
  if (periodFromCookie) {
    return periodFromCookie;
  }
  return visibilityPeriod;
}

export function pathToSearchString(path: readonly Types.ThinAttributeValue[] | undefined): string {
  return path?.length ? `?path=${path.map(value => value.id).join(',')}` : '';
}

export function searchStringToPathIds(search: string): readonly number[] {
  const pathParam = new URLSearchParams(search).get('path');
  if (!pathParam) {
    return [];
  }
  return pathParam.split(',').map(s => parseInt(s, 10));
}

export function pathIdsToForecastSearchString(pathIds: readonly number[] | undefined) {
  return pathIds?.length ? `?path=${pathIds.join(',')}` : '';
}

export function pathToSearchStringWithGranularity(
  path: readonly Types.ThinAttributeValue[],
  granularity?: Types.CalendarUnit | null
) {
  if (path.length && granularity) {
    return `${pathToSearchString(path)}&granularity=${granularity.toLowerCase()}`;
  } else if (path.length) {
    return pathToSearchString(path);
  } else if (granularity) {
    return `?granularity=${granularity.toLowerCase()}`;
  } else {
    return '';
  }
}

export function getForecastLabel(
  npiConfig: Types.NewProductIntroductionConfig | null,
  getForecastDisplayName: () => string
) {
  if (npiConfig && !npiConfig.newProductIntroduction.archiveDate) {
    return 'New Product';
  }
  return getForecastDisplayName();
}

// Get the plan demand source (PDS) set for the current node. If we are on the root node, then
// the PDS is TOTAL, if we are at any node where the path contains a partner, then we will choose
// the PDS set for the partner. If there is no PDS set, then we will default to TOTAL.
export const getPlanDemandSourceForPath = (
  selectedPath: readonly Types.AttributeFilter[] | null,
  planDemandSourceMap: Map<number, Types.PlanDemandSource>,
  planDemandSourceAttribute: Types.Attribute
): Types.PlanDemandSource | null => {
  if (!selectedPath || selectedPath.length === 0) {
    return null;
  }
  const demandSourceAttributeFilter = selectedPath.find(
    filter => filter.attributeInstance.attribute.id === planDemandSourceAttribute.id
  );
  if (!demandSourceAttributeFilter) {
    return null;
  }
  return planDemandSourceMap.get(assertTruthy(demandSourceAttributeFilter.values[0].id), null);
};

export const renameMetricsForDemandPlanning = (result: PlanningWidgetData): PlanningWidgetData => {
  const computeResult = getComputeResult(result);
  if (!computeResult) {
    return result;
  }
  return {
    ...result,
    data: new CompositeResult<ComputeResultExtended | Types.CalendarEventResult>({
      ...result.data,
      compute: new ComputeResultExtended({
        columnGroupings: result.data!.compute.columnGroupings,
        data: result.data!.compute.data,
        totalRowCount: result.data!.compute.totalRowCount,
        metrics: result.data!.compute.metrics.map(instance => ({
          ...instance,
          metric: {
            ...instance.metric,
            displayName:
              UPDATED_PLAN_METRIC_DISPLAY_NAMES_BY_METRIC_NAME[instance.metric.name] ??
              instance.metric.displayName,
          },
        })),
        requestId: result.data!.compute.requestId,
        rowGroupings: result.data!.compute.rowGroupings,
        computeTimeMilliseconds: undefined,
      }),
    }),
  };
};

export function getDiscontinuedSkuPathAsKey(
  path: readonly Types.ThinAttributeValue[] | readonly Types.AttributeValue[]
): readonly number[] {
  return path.map(thinAttributeValue => thinAttributeValue.id!);
}

export function trimToDirectImportMainPath(
  path: readonly Types.ThinAttributeValue[] | readonly Types.AttributeValue[],
  isDirectImportEnabled: boolean | undefined,
  planHierarchySize: number
): readonly number[] {
  return getDiscontinuedSkuPathAsKey(
    isDirectImportEnabled ? path.slice(0, planHierarchySize - 1) : path
  );
}

export function getPlanInputsComputeRequest(
  currentUser: CurrentUser,
  filters: readonly Types.AttributeFilter[],
  planAttributeInstances: readonly Types.AttributeInstance[],
  metrics: readonly Types.MetricInstance[]
) {
  const request: Types.ComputeRequest = {
    calendar: currentUser.settings.analysisSettings.calendar,
    columnGroupings: [],
    options: null,
    devOptions: null,
    evaluationDate: moment().format(DATE_FORMAT),
    computeEvaluationDateTime: null,
    filters,
    postComputeFilters: [],
    metricFilterGroupings: [],
    metricFilters: [],
    metrics,
    removeDoubleCounting: false,
    requestId: uuid(),
    rowGroupings: planAttributeInstances,
    type: Types.ComputeRequestType.FLAT,
    rowLimit: null,
  };

  return Api.Compute.compute(request).then((response: Types.ComputeResult) =>
    ComputeResultExtended.fromJS(request, response)
  );
}
