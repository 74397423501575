import equal from 'fast-deep-equal';
import stableJsonStringify from 'fast-json-stable-stringify';
import memoizeOne from 'memoize-one';
import {stringify} from 'query-string';
import {v4 as uuid} from 'uuid';

import {store} from 'redux/store/store';
import {ApiError} from 'types/error';
import type {FetchExtras, RequestMethod} from 'utils/ajax';
import {assertNonNullish} from 'utils/assert';
import {logMessage} from 'utils/exceptions';

import {isNonNullish, isNullish} from './functions';
import {ApiSelector} from './useApi';

export type {FetchExtras};

export function encodePathString(pathTemplate: string, params: any) {
  return Object.keys(params).reduce(
    (template, key) => template.replace(`{${key}}`, encodeURIComponent(params[key])),
    pathTemplate
  );
}

// Note: this is a globally mutable map for memoizing resource creation. This ensures that there's a singleton Resource
// for every request, so that each request can mutably manage the current state of that request. While this is appropriate
// for this specific scenario, this runs counter to Alloy's typical coding practices (heavily favouring immutability,
// particularly in any sort of non-local context). This should NOT be copied elsewhere without a very strong reason to,
// and immutable approaches should be strongly considered as an alternative.
export const private__mutableResourceCache = new Map<
  string,
  {usages: number; resource: Resource<unknown>; deleteTimeout: NodeJS.Timeout | null}
>();
// abort signals can't be uniquely serialized to JSON so we need to replace them with a marker value instead
const signalRegistry = new WeakMap<AbortSignal, string>();
const serializeSignalForCache = (signal: AbortSignal | undefined) => {
  if (!signal) {
    return signal;
  }
  if (signalRegistry.has(signal)) {
    return signalRegistry.get(signal);
  } else {
    const id = `signal: ${uuid()}`;
    signalRegistry.set(signal, id);
    return id;
  }
};

type Listener = () => void;
const resourceCacheListeners: Set<Listener> = new Set();

export const subscribeToResourceCache = (listener: Listener) => {
  resourceCacheListeners.add(listener);
};
export const unsubscribeFromResourceCache = (listener: Listener) => {
  resourceCacheListeners.delete(listener);
};

function fireResourceCacheListeners() {
  resourceCacheListeners.forEach(listener => listener());
}

let hasLoggedCacheExpiryWarning = false; // eslint-disable-line fp/no-let
const RESOURCE_CACHE_EXPIRATION = 60 * 60 * 1000;
// A promise that never resolves, for use in dev to suspend a given request to inspect its loading state
export const FROZEN_PROMISE = new Promise(() => {});

/**
 * Note: this is exposed separately as putting it on the resource directly causes typing issues.
 */
export function invalidate<T>(resource: Resource<T>, optimisticUpdate?: (oldVal: T) => T) {
  invalidatePrivate(resource, optimisticUpdate);

  const currentVendorId = store.getState().user.vendor?.id;
  // Ensure that the corresponding resource with/without a specified vendorId is also invalidated
  if (isNonNullish(resource.extras?.vendorId) && resource.extras.vendorId === currentVendorId) {
    invalidatePrivate(resource.copyWithVendorId(undefined));
  } else if (isNullish(resource.extras?.vendorId) && isNonNullish(currentVendorId)) {
    invalidatePrivate(resource.copyWithVendorId(currentVendorId));
  }
}

function invalidatePrivate<T>(resource: Resource<T>, optimisticUpdate?: (oldVal: T) => T) {
  if (optimisticUpdate && resource.currentValue !== RESOURCE_NOT_SET) {
    resource.currentValue = optimisticUpdate(resource.currentValue);
    resource.private__cachedTransforms.clear();
    resource.private__fireListeners();
    // Re-fetch the data in the background to ensure that things are correctly up to date
    resource.privateDoNotUse_fetch();
  } else {
    resource.private__cachedTransforms.clear();
    resource.currentValue = RESOURCE_NOT_SET;
    resource.currentRequest = RESOURCE_NOT_SET;
    resource.currentError = RESOURCE_NOT_SET;
    resource.private__fireListeners();
  }
}

export function invalidateFailedRequests() {
  Array.from(private__mutableResourceCache.values())
    .filter(({resource}) => resource.currentError !== RESOURCE_NOT_SET)
    .forEach(({resource}) => invalidate(resource));
}

export function invalidateAllMatchingRequests<T>(
  resourceToInvalidate: Resource<T>,
  optimisticUpdate?: (oldVal: T) => T
) {
  Array.from(private__mutableResourceCache.values())
    .filter(({resource}) =>
      resource.matchesUrl(assertNonNullish(resourceToInvalidate.method), resourceToInvalidate.url)
    )
    .forEach(({resource}) => invalidate(resource as Resource<T>, optimisticUpdate));
}

export function invalidateAllCachedResources() {
  Array.from(private__mutableResourceCache.values()).forEach(({resource}) => invalidate(resource));
}

export function suspendIf(shouldSuspend: boolean, maybePromise: Promise<unknown> | null = null) {
  if (!shouldSuspend) {
    return;
  }
  if (maybePromise) {
    throw maybePromise;
  } else {
    // if the relevant request hasn't been initiated yet, wait for it to be
    throw new Promise(resolve => {
      setTimeout(resolve, 50);
    });
  }
}

// Note: we use a symbol to represent unset values as it's a value that an
// endpoint cannot return (unlike null or undefined)
export const RESOURCE_NOT_SET = Symbol.for('resource_not_set');
type NotSet = typeof RESOURCE_NOT_SET;

export class Resource<T> {
  readonly method: RequestMethod | undefined; // undefined only when no-op resource
  readonly url: string;
  readonly pathParams: object;
  private readonly queryParams: object;
  private readonly body?: unknown;
  readonly extras?: FetchExtras;
  private readonly defaultOn403?: T;
  private readonly keepForever: boolean;

  private readonly listeners: Set<Listener> = new Set();
  readonly private__cachedTransforms: Map<ApiSelector<unknown>, unknown> = new Map();

  currentRequest: Promise<T> | NotSet = RESOURCE_NOT_SET;
  currentValue: T | NotSet = RESOURCE_NOT_SET;
  currentError: Error | NotSet = RESOURCE_NOT_SET;

  constructor(
    method: RequestMethod | undefined,
    url: string,
    pathParams: object,
    queryParams: object,
    body?: unknown,
    extras?: FetchExtras,
    defaultOn403?: T,
    keepForever = false
  ) {
    this.method = method;
    this.url = url;
    this.pathParams = pathParams;
    this.queryParams = queryParams;
    this.body = body;
    this.extras = extras;
    this.defaultOn403 = defaultOn403;
    this.keepForever = keepForever;

    if (private__mutableResourceCache.has(this.getUniqueKey())) {
      return private__mutableResourceCache.get(this.getUniqueKey())!.resource as Resource<T>;
    } else {
      private__mutableResourceCache.set(this.getUniqueKey(), {
        usages: 0,
        resource: this,
        deleteTimeout: this.createCacheExpiryTimer(),
      });
      fireResourceCacheListeners();
    }
  }

  readonly devOnly__toggleFreezing = () => {
    if (this.currentRequest === FROZEN_PROMISE) {
      this.currentRequest = RESOURCE_NOT_SET;
      this.private__fireListeners();
    } else {
      this.currentValue = RESOURCE_NOT_SET;
      this.currentError = RESOURCE_NOT_SET;
      this.currentRequest = FROZEN_PROMISE as Promise<T>;
      this.private__fireListeners();
    }
  };

  readonly getUniqueKey = memoizeOne(() =>
    stableJsonStringify({
      method: this.method,
      url: this.url,
      pathParams: this.pathParams,
      queryParams: this.queryParams,
      body: this.body,
      extras: {
        ...this.extras,
        signal: serializeSignalForCache(this.extras?.signal),
      },
    })
  );

  private readonly createCacheExpiryTimer = () => {
    return setTimeout(
      () => {
        if (this.keepForever) {
          return;
        }
        if (private__mutableResourceCache.get(this.getUniqueKey())!.usages === 0) {
          private__mutableResourceCache.delete(this.getUniqueKey());
          fireResourceCacheListeners();
        } else if (!hasLoggedCacheExpiryWarning) {
          logMessage(
            'Unexpected: cache expiry timer triggered but entry still has usages',
            'warning'
          );
          hasLoggedCacheExpiryWarning = true;
        }
      },
      this.extras?.signal ? 0 : RESOURCE_CACHE_EXPIRATION
    );
  };

  readonly subscribe = (listener: Listener) => {
    this.listeners.add(listener);
    const cached = private__mutableResourceCache.get(this.getUniqueKey())!;
    if (cached.usages === 0) {
      clearTimeout(cached.deleteTimeout!);
      cached.deleteTimeout = null;
    }
    cached.usages++;
    fireResourceCacheListeners();
  };

  readonly unsubscribe = (listener: Listener) => {
    this.listeners.delete(listener);
    const cached = private__mutableResourceCache.get(this.getUniqueKey())!;
    cached.usages--;
    if (cached.usages === 0) {
      cached.deleteTimeout = this.createCacheExpiryTimer();
    }
    fireResourceCacheListeners();
  };

  readonly private__fireListeners = () => {
    this.listeners.forEach(listener => listener());
    fireResourceCacheListeners();
  };

  readonly matchesUrl = (method: RequestMethod, url: string) => {
    return this.method === method && this.url === url;
  };

  readonly isComplete = () => {
    return this.currentValue !== RESOURCE_NOT_SET || this.currentError !== RESOURCE_NOT_SET;
  };

  readonly privateDoNotUse_fetchIfNeeded = (): Promise<T> => {
    if (this.currentRequest !== RESOURCE_NOT_SET) {
      return this.currentRequest;
    }
    return this.privateDoNotUse_fetch();
  };

  readonly copyWithVendorId = (vendorId: number | undefined) => {
    return new Resource(
      this.method,
      this.url,
      this.pathParams,
      this.queryParams,
      this.body,
      {...this.extras, vendorId},
      this.defaultOn403,
      this.keepForever
    );
  };

  readonly privateDoNotUse_fetch = (): Promise<T> => {
    const promise =
      this.method === undefined
        ? (Promise.resolve(undefined) as Promise<unknown> as Promise<T>)
        : apiRequestWrapper<T>(
            this.method,
            this.url,
            this.pathParams,
            this.queryParams,
            this.body,
            this.extras,
            this.defaultOn403
          );
    this.currentRequest = promise;
    promise
      .then(val => {
        // If the resource was optimistically updated and the re-fetched value is the same as the server-provided one,
        // bail out of updating as an optimization, to prevent recalculating things memoized using shallow equality.
        // This also potentially avoids subtle bugs if a reference to old values is somehow kept and then expected to
        // be shallow equal to the new values.
        if (promise === this.currentRequest) {
          this.currentRequest = RESOURCE_NOT_SET;
          if (!equal(val, this.currentValue)) {
            this.currentValue = val;
            this.private__cachedTransforms.clear();
          }
          this.private__fireListeners();
        }
      })
      .catch(err => {
        if (promise === this.currentRequest) {
          this.currentError = err;
          this.currentRequest = RESOURCE_NOT_SET;
          this.currentValue = RESOURCE_NOT_SET;
          this.private__fireListeners();
        }
      });
    return promise;
  };
}

/**
 * This function wraps 'request' from 'utils/ajax', but without any non-type compile-time dependencies
 * on alloy-defined modules. This avoids a circular dependency between 'api' and 'utils/ajax'.
 */
const ajaxUtils = import('utils/ajax');
let makeRequest: typeof import('utils/ajax').request | null = null; // eslint-disable-line fp/no-let
ajaxUtils.then(utils => {
  makeRequest = utils.request;
});
export async function apiRequestWrapper<T>(
  method: RequestMethod,
  url: string,
  pathParams: any,
  queryParams: any,
  body?: any,
  extras?: FetchExtras,
  defaultOn403?: T,
  accept?: string,
  contentType?: string
): Promise<T> {
  const queryString = stringify(queryParams);
  // Avoid waiting unnecessarily once importing utils/ajax has succeeded once; this allows for proper stack traces and
  // also makes requests initiate faster (as it's possible there's something expensive waiting on the event loop)
  const request = makeRequest ?? (await ajaxUtils).request;
  const promise: Promise<T> = request(
    method,
    encodePathString(url, pathParams) + (queryString ? '?' + queryString : ''),
    body,
    extras,
    accept,
    contentType
  );
  return promise.catch((error: any) => {
    if (defaultOn403 !== undefined && error instanceof ApiError && error.code === 403) {
      logMessage(`Forbidden: ${error.requestUrl}`, 'warning');
      return defaultOn403;
    }
    throw error;
  }) as Promise<T>;
}

type ResourceWrapper<A extends unknown[], T> = {
  (...args: A): Promise<T>;
  getResource: (...args: A) => Resource<T>;
};

// A resource to be used with useApi when no request should be made. Will always return a value of undefined.
// If an API request returns 'null' then useApi returns the value as 'undefined' so this is consistent with null being returned from a request.
export const NoopResource = new Resource<undefined>(
  undefined,
  'no-op',
  {},
  {},
  undefined,
  undefined,
  undefined,
  true
);

export function apiWrapper<A extends unknown[], T>(
  res: (...args: A) => Resource<T>
): ResourceWrapper<A, T> {
  const func = (...args: A) => res(...args).privateDoNotUse_fetch();
  func.getResource = res;
  return func;
}
