import * as Api from 'api';
import {PromiseAction} from 'redux/store/middleware/promise';
import * as Types from 'types';
import {PromiseStatus} from 'utils/status';

import ActionType from './types';

export type PoliciesAction = {
  type: ActionType.SetPolicyData;
} & PromiseAction<readonly Types.UserFilters[]>;

export function fetchPolicyData(): PoliciesAction {
  return {
    promise: Api.Policies.getPolicies(),
    type: ActionType.SetPolicyData,
    state: PromiseStatus.request,
  };
}
