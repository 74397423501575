import {List} from 'immutable';

import * as Types from 'types';
import {isNonNullish} from 'utils/functions';
import {isValidJsonObject} from 'utils/json';

import {CredentialRequestStatus} from './types';

export const SFTP_GROUP_NAME = 'sftp';
export const AZURE_ALLOY_HOSTED_GROUP_NAME = 'azure_export';
// The credential groups for Alloy managed buckets only need a list of principals or users to give access to
export const GCS_ALLOY_HOSTED_GROUP_NAME = 'google_cloud_storage_alloy_hosted_data_share';
export const S3_ALLOY_HOSTED_GROUP_NAME = 'amazon_s3_alloy_hosted_data_share';
// The credential group for S3 buckets includes an access key id and secret access key to create, modify, and upload files to with buckets
export const S3_GROUP_NAME = 'aws_s3';
export const NULL_CREDENTIAL_SET_PLACEHOLDER = 'NULL_CREDENTIAL_SET_PLACEHOLDER';
export const NULL_CREDENTIAL_SET_PLACEHOLDER_LABEL = '(Default)';

export function standardizeCredentialSetName(credentialSetName: string): string;
export function standardizeCredentialSetName(credentialSetName: string | null): null;
export function standardizeCredentialSetName(credentialSetName: string | null): string | null {
  return credentialSetName === null
    ? null
    : credentialSetName.replace(/ /g, '_').replace(/\W/g, '_');
}

export const validateCredentialField = (field: Types.CredentialField, valueStr: any) => {
  if (field.type === Types.CredentialFieldType.map && !isValidJsonObject(valueStr)) {
    return 'JSON is invalid.';
  }
  return null;
};

// Keep in sync with backend
export function canObtainLoginCredentials(groupName: string): boolean {
  return groupName === 'walmart_luminate_basic';
}

export function isMatchingCredentialRequest(
  request: Types.CredentialRequest,
  vendorCredentials: Types.VendorCredentials
) {
  return (
    request.credentialSetName === vendorCredentials.credentialSetName &&
    request.credentialGroupName === vendorCredentials.groupName
  );
}

export function getLatestCredentialRequest(
  requests: readonly Types.CredentialRequest[]
): Types.CredentialRequest | null {
  return List(requests)
    .sortBy(req => req.id)
    .last(null);
}

export function getCredentialRequestStatus(
  request: Types.CredentialRequest | null
): CredentialRequestStatus {
  if (!request) {
    return CredentialRequestStatus.UNKNOWN;
  } else if (request?.completedAt !== null) {
    return CredentialRequestStatus.UP_TO_DATE;
  } else if (request.requestType === Types.CredentialsRequestType.UPDATE_PENDING) {
    return CredentialRequestStatus.OWNER_NOT_SPECIFIED;
  } else if (
    isNonNullish(request.sendGridCode) &&
    (request.sendGridCode < 200 || request.sendGridCode >= 300)
  ) {
    return CredentialRequestStatus.EMAIL_FAILED;
  } else if (Date.parse(request.expiresAt) < Date.now()) {
    return CredentialRequestStatus.REQUEST_EXPIRED;
  }
  return CredentialRequestStatus.REQUEST_PENDING;
}

export function getCredentialSetNameSuffix(credentialSetName: string | null | undefined): string {
  return credentialSetName ? ` (${credentialSetName})` : '';
}
