import './FadeToBottomTransition.scss';

import {useTransition, animated} from '@react-spring/web';
import React from 'react';

const FadeToBottomTransition: React.FunctionComponent<Props> = ({children, visible}) => {
  const transition = useTransition(visible, {
    from: {opacity: 0, transform: 'translateY(100%)'},
    enter: {opacity: 1, transform: 'translateY(0%)'},
    leave: {opacity: 0, transform: 'translateY(100%)'},
  });
  return transition(
    (styles, visible) =>
      visible && (
        <animated.div className="FadeToBottomTransition" style={styles}>
          {children}
        </animated.div>
      )
  );
};
FadeToBottomTransition.displayName = 'FadeToBottomTransition';
interface Props {
  children: React.ReactNode;
  visible: boolean;
}
export default FadeToBottomTransition;
