import {List} from 'immutable';

import {CurrentUser} from 'redux/reducers/user';
import {AttributeHierarchies, PartnersById} from 'toolkit/attributes/types';
import {PEWTER_COMPUTED_FORECAST_TYPES} from 'toolkit/metrics/editor/types';
import {ForecastTypesByForecastedMetricName} from 'toolkit/metrics/types';
import {hasPermission} from 'toolkit/users/utils';
import {ViewUrlParams, WorkflowSlug} from 'toolkit/views/types';
import {PROMOTIONS_SLUG} from 'toolkit/views/utils';
import {toBase64Params} from 'toolkit/views/view-urls';
import * as Types from 'types';
import {Workflow} from 'types';
import {assertNonNullish} from 'utils/assert';
import {isNullish} from 'utils/functions';

type DiagnosticsWorkflow = {
  [K in keyof typeof Workflow]: K extends `DIAGNOSTICS_${string}` ? K : never;
}[keyof typeof Workflow];

type NonDiagnosticsWorkflow = Exclude<Types.Workflow, DiagnosticsWorkflow>;

function getBaseWorkflowUrl(
  workflow: Exclude<NonDiagnosticsWorkflow, Types.Workflow.PROMOTION_ANALYSIS>,
  vendorName: string
) {
  return `/${vendorName}/workflow/${getWorkflowSlug(workflow)}`;
}

export function getWorkflowUrl(
  workflow: NonDiagnosticsWorkflow,
  vendorName: string,
  viewParams?: List<ViewUrlParams | null> | null
) {
  const params = toBase64Params(viewParams);
  const paramsPart = params ? `?params=${params}` : '';
  const baseUrl =
    workflow === Workflow.PROMOTION_ANALYSIS
      ? getPromoAnalysisUrl(vendorName)
      : getBaseWorkflowUrl(workflow, vendorName);
  return `${baseUrl}${paramsPart}`;
}

export function getPromoAnalysisUrl(vendorName: string, eventId?: number | null) {
  const baseUrl = `/${vendorName}/${PROMOTIONS_SLUG}`;
  if (isNullish(eventId)) {
    return baseUrl;
  }
  return `${baseUrl}/${eventId}`;
}

export function getPartnerLikeAttribute(
  attributeHierarchies: AttributeHierarchies
): Types.Attribute {
  // The highest order location attribute is considered Partner-like
  return assertNonNullish(
    attributeHierarchies.get(Types.AttributeHierarchyType.LOCATION, []).at(0)
  );
}

export function isPromoAnalysisEnabled(currentUser: CurrentUser) {
  return hasPermission(currentUser, Types.PermissionKey.WORKFLOW_USE_PROMOTION_ANALYSIS);
}

export function isNovaRecommendationsEnabled(currentUser: CurrentUser, partnersById: PartnersById) {
  return (
    hasPermission(currentUser, Types.PermissionKey.WORKFLOW_USE_WALMART_NOVA_RECOMMENDATIONS) &&
    partnersById.valueSeq().some(partner => partner.name === 'Walmart')
  );
}

export function getAccessibleWorkflows(user: CurrentUser, partners: PartnersById) {
  const workflows: NonDiagnosticsWorkflow[] = [];
  if (isPromoAnalysisEnabled(user)) {
    workflows.push(Types.Workflow.PROMOTION_ANALYSIS);
  }
  if (isNovaRecommendationsEnabled(user, partners)) {
    workflows.push(Types.Workflow.WALMART_NOVA_RECOMMENDATIONS);
  }
  return workflows;
}

export function getWorkflowSlug(workflow: NonDiagnosticsWorkflow): WorkflowSlug {
  return workflow.toLowerCase().replace(/_/g, '-') as WorkflowSlug;
}

export function getAvailableSalesForecastTypes<T extends Types.ForecastType>(
  candidateForecastTypes: readonly T[],
  availableForecastTypesByMetric: ForecastTypesByForecastedMetricName
): readonly T[] {
  return candidateForecastTypes.filter(
    forecastType =>
      PEWTER_COMPUTED_FORECAST_TYPES.includes(forecastType) ||
      availableForecastTypesByMetric.get('sales_units_net')?.has(forecastType)
  );
}
