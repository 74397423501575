import './ModalDialog.scss';

import classNames from 'classnames';
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import {Modal, ModalBody} from 'react-bootstrap';

import {DialogFooter, DialogFooterProps} from 'toolkit/components/DialogFooter';
import Icon from 'toolkit/components/Icon';
import {IconSpec} from 'toolkit/icons/types';
import {TopBarProps} from 'toolkit/top-bar/TopBar';

import Button, {ButtonDisplayVariant, ButtonIconPosition} from './Button';

export type ModalSize = 'sm' | 'lg' | 'xl';

function autofocus(element: HTMLElement | null) {
  element?.focus();
}

const INPUT_ELEMENTS = new Set(['INPUT', 'TEXTAREA']);

const ModalDialog: React.FC<ModalDialogProps> = ({
  backdrop = true,
  className,
  isFooterVisible = true,
  isOpen = true,
  keyboard = true,
  submitOnEnterDisabled = false,
  ...props
}) => {
  const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const focusedElement = document.activeElement;
    const wasInInputElement = focusedElement && INPUT_ELEMENTS.has(focusedElement.tagName);

    if (event.key === 'Enter' && !wasInInputElement && props.isConfirmEnabled) {
      props.onConfirm!();
    }
  };

  return (
    <Modal
      animation={false}
      backdrop={backdrop}
      backdropClassName={props.backdropClassName}
      centered={props.isVerticallyCentered}
      className="alloy-modal-dialog-container"
      dialogClassName={props.dialogClassName}
      enforceFocus={false}
      keyboard={keyboard}
      show={isOpen}
      size={props.size}
      onHide={props.onClose}
      onKeyPress={submitOnEnterDisabled ? undefined : onKeyPress}
    >
      <div
        ref={autofocus}
        className={classNames(
          className,
          'ModalDialog',
          {'with-topbar': !!props.topBar},
          {'with-footer': isFooterVisible}
        )}
        tabIndex={-1}
      >
        <div className="modal-dialog-header">
          <h2>
            {props.titleIcon && <Icon icon={props.titleIcon} />} {props.title}
          </h2>
          {props.customTitleContent && <>{props.customTitleContent}</>}
          <Button
            className="close-button"
            displayVariant={ButtonDisplayVariant.TERTIARY}
            icon="times"
            iconPosition={ButtonIconPosition.ICON_ONLY}
            onClick={props.onClose}
          />
        </div>
        {props.topBar}
        <ModalBody
          className={classNames({
            'zero-padding': props.zeroPadding || props.isBodyUnstyled,
            unstyled: props.isBodyUnstyled,
          })}
        >
          {props.children}
        </ModalBody>
        {isFooterVisible && (
          <DialogFooter
            cancelActionName={props.cancelActionName}
            confirmActionName={props.confirmActionName}
            confirmButton={props.confirmButton}
            confirmButtonDisplayVariant={props.confirmButtonDisplayVariant}
            confirmStatus={props.confirmStatus}
            customFooterContent={props.customFooterContent}
            isCancelVisible={props.isCancelVisible}
            isConfirmEnabled={props.isConfirmEnabled}
            isConfirmVisible={props.isConfirmVisible}
            isLinkWarning={props.isLinkWarning}
            linkActionName={props.linkActionName}
            size={props.size}
            warnEditOnProdDemoVendor={props.warnEditOnProdDemoVendor}
            onCancel={props.onCancel}
            onClose={props.onClose}
            onConfirm={props.onConfirm}
            onLinkClick={props.onLinkClick}
          >
            {props.footerButtons}
          </DialogFooter>
        )}
      </div>
    </Modal>
  );
};

ModalDialog.displayName = 'ModalDialog';
export interface ModalDialogProps extends DialogFooterProps {
  backdrop?: boolean | 'static';
  backdropClassName?: string;
  className?: string;
  customTitleContent?: React.ReactNode;
  dialogClassName?: string;
  footerButtons?: React.ReactNode;
  isFooterVisible?: boolean;
  isVerticallyCentered?: boolean;
  isOpen?: boolean;
  isBodyUnstyled?: boolean;
  keyboard?: boolean;
  size?: ModalSize;
  submitOnEnterDisabled?: boolean;
  title: React.ReactNode;
  titleIcon?: IconSpec;
  topBar?: React.ReactElement<TopBarProps> | null;
  zeroPadding?: boolean;
}

// eslint-disable-next-line no-restricted-syntax
export default ModalDialog;
