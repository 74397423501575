import {Map, Set} from 'immutable';

import {AttributeHierarchies, AttributeLikeHierarchyCollection} from 'toolkit/attributes/types';
import {capitalize} from 'toolkit/format/text';
import * as Types from 'types';
import {isTruthy} from 'utils/functions';

const DEFAULT_HIERARCHY_DISPLAY_NAME_PREFIX = 'Default ';
const DEFAULT_HIERARCHY_DISPLAY_NAME_SUFFIX = ' Hierarchy';

export function attributeLikeIndicesByAttributeHierarchyCollectionIndices<T>(
  attributeHierarchyCollections: ReadonlyArray<AttributeLikeHierarchyCollection<T>>
): Map<number, number> {
  // eslint-disable-next-line fp/no-let
  let aIndex = 0;
  return attributeHierarchyCollections
    .map<Map<number, number>>((attributeHierarchyCollection, index) => {
      if (!attributeHierarchyCollection.attributeLikeValues.length) {
        throw new Error('Cannot map indices of an empty list.');
      }

      aIndex += attributeHierarchyCollection.attributeLikeValues.length;
      return Map.of(index, aIndex - 1);
    })
    .reduce(
      (reduction: {map: Map<number, number>}, value) => {
        reduction.map = reduction.map.merge(value);
        return reduction;
      },
      {map: Map<number, number>()}
    ).map;
}

export function fromAttributeHierarchyCollectionList<T>(
  attributeHierarchyCollections: ReadonlyArray<AttributeLikeHierarchyCollection<T>>
): readonly T[] {
  return attributeHierarchyCollections
    .map<readonly T[]>(
      attributeHierarchyCollection => attributeHierarchyCollection.attributeLikeValues
    )
    .reduce(
      (reduction: {result: readonly T[]}, value) => {
        reduction.result = [...reduction.result, ...value];
        return reduction;
      },
      {result: []}
    ).result;
}

export function getAttributeHierarchyCollectionByDisplayName<T>(
  attributeHierarchyCollections: ReadonlyArray<AttributeLikeHierarchyCollection<T>>,
  getAttributeType: (attributeLike: T) => Types.AttributeType,
  getDisplayName: (attributeLike: T) => string
) {
  return Map<string, AttributeLikeHierarchyCollection<T>>(
    attributeHierarchyCollections.map(
      (attributeHierarchyCollection): [string, AttributeLikeHierarchyCollection<T>] => [
        getAttributeHierarchyCollectionDisplayName(
          attributeHierarchyCollection,
          getAttributeType,
          getDisplayName
        ),
        attributeHierarchyCollection,
      ]
    )
  );
}

export function getAttributeHierarchyCollectionDisplayName<T>(
  attributeHierarchyCollection: AttributeLikeHierarchyCollection<T>,
  getAttributeType: (attributeLike: T) => Types.AttributeType,
  getDisplayName: (attributeLike: T) => string
): string {
  if (attributeHierarchyCollection.attributeLikeValues.length > 1) {
    return `${DEFAULT_HIERARCHY_DISPLAY_NAME_PREFIX}${capitalize(
      attributeHierarchyCollection.attributeHierarchyType ??
        getAttributeType(attributeHierarchyCollection.attributeLikeValues[0])
    )}${DEFAULT_HIERARCHY_DISPLAY_NAME_SUFFIX}`;
  }

  return getDisplayName(attributeHierarchyCollection.attributeLikeValues[0]);
}

function populateAttributeSegment<T>(
  attributeSegment: readonly T[]
): ReadonlyArray<AttributeLikeHierarchyCollection<T>> {
  return attributeSegment.map(attributeInstance => ({
    attributeLikeValues: [attributeInstance],
  }));
}

function isHierarchy<T>(
  hierarchy: readonly Types.Attribute[],
  instances: readonly T[],
  getAttributeId: (attributeLike: T) => number | null | undefined
): boolean {
  return (
    instances.length >= hierarchy.length &&
    hierarchy.every((attribute, index) => attribute.id === getAttributeId(instances[index]))
  );
}

export function toDefaultAttributeHierarchyCollectionList<T>(
  attributeInstances: readonly T[] | null,
  defaultAttributeHierarchies: AttributeHierarchies,
  getAttributeId: (attributeLike: T) => number | null | undefined
): ReadonlyArray<AttributeLikeHierarchyCollection<T>> {
  if (!attributeInstances) {
    return [];
  }

  const hierarchies = defaultAttributeHierarchies.filter(hierarchy => hierarchy.length > 0);

  // eslint-disable-next-line fp/no-let
  let jumpItemCount = 0;

  return attributeInstances
    .map((instance, index) => {
      if (jumpItemCount > 0) {
        --jumpItemCount;
        return null;
      }

      const partialList = attributeInstances.slice(index);
      const [matchingHierarchyType, matchingDefaultHierarchy] = hierarchies.findEntry(hierarchy =>
        isHierarchy(hierarchy, partialList, getAttributeId)
      ) ?? [null, []];
      if (!matchingDefaultHierarchy || matchingDefaultHierarchy.length <= 1) {
        return {
          attributeLikeValues: [instance],
        };
      }

      // -1 because the current item doesn't have to be jumped over
      jumpItemCount = matchingDefaultHierarchy.length - 1;
      return {
        attributeLikeValues: partialList.slice(0, matchingDefaultHierarchy.length),
        attributeHierarchyType: matchingHierarchyType!,
      };
    })
    .filter(isTruthy);
}

function containsAttributes<T>(
  attributes: readonly Types.Attribute[],
  fromList: readonly T[],
  getAttribute: (attributeLike: T) => Types.Attribute | null | undefined
) {
  const fromAttributes = fromList.map(getAttribute).filter(isTruthy);
  return (
    attributes.length > 0 && Set(attributes).intersect(fromAttributes).size === attributes.length
  );
}

export function toDefaultAttributeHierarchyCollectionSelectList<T>(
  attributeInstances: readonly T[] | null,
  defaultAttributeHierarchies: AttributeHierarchies,
  getAttribute: (attributeLike: T) => Types.Attribute | null | undefined,
  getAttributeLikeFromAttribute: (attribute: Types.Attribute) => T
): ReadonlyArray<AttributeLikeHierarchyCollection<T>> {
  if (!attributeInstances) {
    return [];
  }

  return defaultAttributeHierarchies
    .filter(attributes => containsAttributes(attributes, attributeInstances, getAttribute))
    .map((attributes, attributeHierarchyType) => {
      return {
        attributeLikeValues: attributes.map(attribute => getAttributeLikeFromAttribute(attribute)),
        attributeHierarchyType,
      };
    })
    .toList()
    .concat(populateAttributeSegment(attributeInstances))
    .toArray();
}
