/**
 * Feature flags are added here as an enum to avoid typos and repetition of strings.
 *
 * If a feature flag is not needed in the frontend, it doesn't need to be added here.
 *
 * Before adding a feature flag here, remember to add the flag in the feature flags CRUD.
 */
export enum FeatureFlag {
  SALES_DIAGNOSTICS = 'SALES_DIAGNOSTICS',
  FORECAST_MODULE = 'FORECAST_MODULE',
  INVENTORY_PLANNING = 'INVENTORY_PLANNING',
  PLAN_APPLICATION = 'PLAN_APPLICATION',
  PLAN_METRICS = 'PLAN_METRICS',
  SHIPPING_LANE_RULES = 'SHIPPING_LANE_RULES',
  SUPPLY_CHAIN = 'SUPPLY_CHAIN',
  EPOCH_SYSTEM = 'EPOCH_SYSTEM',
  PERCENTAGE_BASE_FILTERS = 'PERCENTAGE_BASE_FILTERS',
  DATABRICKS_DATA_SHARE = 'DATABRICKS_DATA_SHARE',
  SEGMENT_ATTRIBUTES = 'SEGMENT_ATTRIBUTES',
  METRIC_ATTRIBUTE_FILTERS = 'METRIC_ATTRIBUTE_FILTERS',
  SELF_SERVE_INTEGRATION = 'SELF_SERVE_INTEGRATION',
  NOTIFICATIONS = 'NOTIFICATIONS',
  METRIC_CONDITIONAL_FORMATTING = 'METRIC_CONDITIONAL_FORMATTING',
  USER_PROFILE = 'USER_PROFILE',
}
