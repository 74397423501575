import {List, Map, Set} from 'immutable';

import * as Api from 'api';
import {Dispatch} from 'redux/reducers';
import {PromiseAction} from 'redux/store/middleware/promise';
import {FeatureFlag} from 'toolkit/feature-flags/types';
import {PUBLIC_VENDOR_ID} from 'toolkit/users/utils';
import * as Types from 'types';
import {PromiseStatus} from 'utils/status';

import ActionType from './types';

export type UserDataAction =
  | {type: ActionType.SetAllowedVendors; vendors: List<Types.Vendor>}
  | {type: ActionType.DeleteUser; email: string}
  | {type: ActionType.SetSavedFeatureFlags; allFeaturesByUser: Map<number, Set<FeatureFlag>>}
  | ({type: ActionType.SetAllUserData} & PromiseAction<readonly Types.User[]>)
  | ({
      type: ActionType.SetAllUserInfoData;
    } & PromiseAction<{[userEmail: string]: Types.UserInfo}>)
  | {type: ActionType.SetSavedUser; user: Types.User}
  | {type: ActionType.SetSavedUserInfo; email: string; userInfo: Types.UserInfo}
  | ({
      type: ActionType.SetAllFeaturesByUser;
    } & PromiseAction<{[userId: string]: readonly FeatureFlag[]}>);

interface IActions {
  setAllowedVendors: (vendors: List<Types.Vendor>) => UserDataAction;
  deleteUser: (email: string) => UserDataAction;
  saveFeatureFlags: (allFeaturesByUser: Map<number, Set<FeatureFlag>>) => UserDataAction;
  fetchAllUserData: () => any;
  fetchUserData: () => UserDataAction;
  fetchUserInfoData: () => UserDataAction;
  fetchAllFeaturesByUser: (vendorId: number) => UserDataAction;
  setSavedUser: (user: Types.User) => UserDataAction;
  setSavedUserInfo: (email: string, userInfo: Types.UserInfo) => UserDataAction;
}

export const UserDataActions: IActions = {
  setAllowedVendors: (vendors: List<Types.Vendor>) => {
    return {
      type: ActionType.SetAllowedVendors,
      vendors,
    };
  },

  deleteUser: (email: string) => {
    return {
      email,
      type: ActionType.DeleteUser,
    };
  },

  saveFeatureFlags: (allFeaturesByUser: Map<number, Set<FeatureFlag>>) => {
    return {
      allFeaturesByUser,
      type: ActionType.SetSavedFeatureFlags,
    };
  },

  fetchAllUserData: () => {
    return (dispatch: Dispatch) => {
      dispatch(UserDataActions.fetchUserData());
      dispatch(UserDataActions.fetchUserInfoData());
    };
  },

  fetchUserData: () => {
    return {
      promise: Api.Authentication.getUsers(),
      type: ActionType.SetAllUserData,
      state: PromiseStatus.request,
    };
  },

  fetchUserInfoData: () => {
    return {
      promise: Api.Authentication.getUserInfos(),
      type: ActionType.SetAllUserInfoData,
      state: PromiseStatus.request,
    };
  },

  fetchAllFeaturesByUser: (vendorId: number) => {
    return {
      promise: Api.FeatureFlags.getFeaturesForUsers(vendorId, {
        vendorId: PUBLIC_VENDOR_ID,
      }) as Promise<{
        [key: string]: readonly FeatureFlag[];
      }>,
      type: ActionType.SetAllFeaturesByUser,
      state: PromiseStatus.request,
    };
  },

  setSavedUser: (user: Types.User) => {
    return {
      type: ActionType.SetSavedUser,
      user,
    };
  },

  setSavedUserInfo: (email: string, userInfo: Types.UserInfo) => {
    return {
      email,
      type: ActionType.SetSavedUserInfo,
      userInfo,
    };
  },
};
