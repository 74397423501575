import './MetricHeaderCell.scss';

import {IHeaderParams} from 'ag-grid-community';
import classNames from 'classnames';
import {Map} from 'immutable';
import React, {useEffect} from 'react';
import {OverlayTrigger, Popover} from 'react-bootstrap';

import Analytics from 'app/analytics/analytics';
import useSelector from 'redux/selectors/useSelector';
import Format from 'toolkit/format/format';
import {TextLengthFormat} from 'toolkit/format/types';
import {DEFAULT_DESCRIPTION} from 'toolkit/metrics/MetricDescriptionTooltip';
import {getEffectiveMetricName, getMetricHeaderName} from 'toolkit/metrics/utils';
import {PeriodIntervalDisplayName} from 'toolkit/time/PeriodInterval';
import {defaultOverlayTriggerTooltipProps} from 'toolkit/utils/react-bootstrap';
import {assertNonNullish} from 'utils/assert';
import {isNonNullish} from 'utils/functions';
import {TableHeaderColorScheme} from 'widgets/types';

import TableSortIndicator from './TableSortIndicator';
import {MetricHeaderParams} from './types';

const MetricHeaderCell: React.FunctionComponent<Props> = ({
  descriptionOverrides = Map(),
  enableSorting = true,
  isTooltipsEnabled = true,
  ...props
}) => {
  const descriptions = useSelector(state => state.analysis.data.metricDescriptions).merge(
    assertNonNullish(descriptionOverrides)
  );
  const onSortRequested = (event: React.MouseEvent<HTMLElement>) => {
    if (enableSorting && !props.isChangingSortDisabled) {
      props.progressSort(event.shiftKey);
    }
  };
  const metricInstance = props.metricInstanceConfig.metricInstance;
  const metricName = getMetricHeaderName(
    props.metricInstanceConfig,
    props.displayName,
    props.hideForecastSuffixes ?? false,
    props.hideCurrencySuffix ?? false
  );
  const headerTitle =
    (props.isIconizedColumn &&
      Format.metricPeriod(metricInstance, {lengthFormat: TextLengthFormat.COMPACT})) ||
    metricName;

  const period = props.onlyShowTimeshift
    ? Format.metricTimeAgo(metricInstance)
    : Format.metricPeriod(metricInstance);
  const prefix = props.isSubtotal ? 'Subtotal Of ' : props.isTotal ? 'Total Of ' : '';
  const popoverTitle = `${prefix}${metricName}`;
  const dollarTypeSubtitle = Format.metricDollarTypeSubtitle(metricInstance);
  const isSubtitleVisible = props.isSubtitleVisible ?? !props.compact;
  useEffect(() => {
    if (
      !props.compact &&
      !props.hidePeriod &&
      period &&
      isNonNullish(dollarTypeSubtitle) &&
      isSubtitleVisible
    ) {
      // If dollar type and period are shown, we need some extra height in the header so things
      // don't get cut off
      props.api.setHeaderHeight(48);
    }
  }, [props.compact, props.api, props.hidePeriod, dollarTypeSubtitle, isSubtitleVisible, period]);

  const popover = (
    <Popover className="metricheadercell-popover" id="aggrid-table-header-popover">
      <Popover.Title>
        <div className="title">{popoverTitle}</div>
        {!props.hidePeriod && (
          <div className="subtitle">
            {period} (
            <PeriodIntervalDisplayName
              isTimeAgoCalendarPeriodAligned={
                metricInstance.arguments.isTimeAgoCalendarPeriodAligned
              }
              options={{lengthFormat: TextLengthFormat.COMPACT}}
              period={metricInstance.arguments.period}
              timeAgo={metricInstance.arguments.timeAgo}
            />
            )
          </div>
        )}
        {isNonNullish(dollarTypeSubtitle) && <div className="subtitle">{dollarTypeSubtitle}</div>}
      </Popover.Title>
      <Popover.Content>
        {props.metricInstanceConfig.customDescription ||
          descriptions.get(getEffectiveMetricName(props.metricInstanceConfig.metricInstance)) ||
          DEFAULT_DESCRIPTION}
        {props.additionalPopoverContent ? `\n\n${props.additionalPopoverContent}` : ''}
      </Popover.Content>
    </Popover>
  );

  const PopoverWrapper =
    props.compact || !isTooltipsEnabled
      ? React.Fragment
      : ({children}: {children: React.ReactElement}) => (
          <OverlayTrigger
            {...defaultOverlayTriggerTooltipProps}
            overlay={popover}
            placement="left"
            onEnter={() => Analytics.track('Metric Header Hover')}
          >
            {children}
          </OverlayTrigger>
        );
  const titleAndSubtitle = (
    <div className="title-container">
      <PopoverWrapper>
        <div className="popover-content">
          <div className="title">
            <span>{`${prefix}${headerTitle}`}</span>
          </div>
          {isSubtitleVisible && !props.hidePeriod && period && (
            <div className="subtitle">{period}</div>
          )}
          {isSubtitleVisible && isNonNullish(dollarTypeSubtitle) && (
            <div className="subtitle">{dollarTypeSubtitle}</div>
          )}
        </div>
      </PopoverWrapper>
    </div>
  );

  return (
    <div
      className={classNames('MetricHeaderCell', {
        'bold-title': props.isBold,
        'color-scheme-grey': props.headerColorScheme === TableHeaderColorScheme.GREY,
        'iconized-column': props.isIconizedColumn,
        'changing-sort-disabled': props.isChangingSortDisabled,
      })}
      id="agHeaderCellLabel"
      onClick={onSortRequested}
    >
      <TableSortIndicator
        column={props.column}
        disabled={!enableSorting}
        gridApi={props.api}
        isCompact={props.compact}
      >
        {titleAndSubtitle}
      </TableSortIndicator>
    </div>
  );
};

MetricHeaderCell.displayName = 'MetricHeaderCell';
export default MetricHeaderCell;

type Props = MetricHeaderParams & IHeaderParams;
